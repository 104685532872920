<template>
  <div class="data_content">
    <div class="rgstr" style="width: 100%;">
      <form ref="dataForm" id="frm" name="frm" class="rgstr_form" enctype="multipart/form-data">
        <div class="table_area none">
          <div class="table_box no_scroll scope_row">
            <table>
              <caption>등록</caption>
              <colgroup>
                <col style="">
                <col style="">
                <col style="">
                <col style="">
              </colgroup>
              <tbody>
                <tr>
                  <th>제목</th>
                  <td>
                    <input type="text" v-model="board.TITLE" value="" />
                  </td>
                </tr>
                <tr>
                  <th>내용</th>
                  <td>
                    <div class="centered">
                      <div class="row row-editor">
                        <div class="editor-container">
                          <textarea class="editor" placeholder="내용을 입력해주세요" v-model="board.CONTENTS"></textarea>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>첨부파일</th>
                  <td>
                    <template v-for="idx in 5" :key="idx">
                      <ul id="attachFileBox" class="add_btn_area" style="display: flex;"
                        v-if="board[`FILE_INFO${idx}`]">
                        <li>
                          <a href="javascript:;">{{ board[`FILE_INFO${idx}`].FILE_ORG_NAME }}</a>
                          <i class="xi-close" @click="deleteFile(board[`FILE_INFO${idx}`].IDX)"></i>
                        </li>
                      </ul>
                      <div v-show="!board[`FILE_INFO${idx}`]">
                        <div class="file_input_box">
                          <div class="file_input_wrap">
                            <input type="file" :name="`fileId${idx}`" :id="`fileId${idx}`" class="flie_input"
                              @change="onFileChange($event)" />
                            <ul>
                              <li>
                                <input type="text" class="file_name" :value="board[`fileId${idx}`]" readonly>
                                <button v-show="board[`fileId${idx}`]" type="button" class="delete_btn"
                                  @click="fileClear(idx)"><i class="xi-close"></i></button>
                              </li>
                            </ul>
                            <label :for="`fileId${idx}`" class="btn sub_btn">파일첨부</label>
                          </div>
                        </div>
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="btn_area">
    <a href="javascript:;" class="btn" @click="goList">목록</a>
    <a href="javascript:;" class="btn save" @click="fnSubmit">저장</a>
  </div>
</template>

<script setup>
// import common from '@/assets/js/ui.common.js'
import { ref, onActivated, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex";
import axios from 'axios'

const $store = useStore();
const $route = useRoute();
const $router = useRouter();
const board = ref({});
const dataForm = ref(null);

const isLogin = computed(() => {
  return $store.getters.getIsLogin();
});

function goList() {
  if (confirm("저장하지 않고 목록으로 가시겠습니까?")) {
    $router.push('/notice/noticeList');
  }
}

function onFileChange(e) {
  let fileElements = e.target;
  let file = fileElements.files || e.dataTransfer.files;
  board.value[e.target.id] = file[0].name;
}

function fileClear(idx) {
  let objId = `fileId${idx}`;
  let fileElements = document.getElementById(objId);
  //파일 비우기.
  fileElements.value = "";
  //파일명 비우기
  board.value[objId] = "";
}

function deleteFile(idx) {
  if (confirm("파일은 즉시 삭제되며 복구가 불가 합니다. 삭제하시겠습니까?")) {
    axios({
      url: "/board/deleteBoardFile.do",
      method: "POST",
      data: {
        'BOARD_IDX': board.value.IDX
        , 'IDX': idx
      }
    }).then(res => {
      console.log(res);
      board.value[`FILE_INFO${idx}`] = null;
      alert("파일 삭제 성공 하었습니다.");
    }).catch(err => {
      console.error(err);
    });
  }
}

function fnSubmit() {
  //validation
  if (confirm('저장하시겠습니까?')) {
    let submitUrl = '';
    if (board.value.IDX) {
      submitUrl = "/board/updateBoard.do";
    } else {
      submitUrl = "/board/insertBoard.do";
    }

    //formData 생성
    let formData = new FormData();
    Object.keys(board.value).forEach(key => formData.append(key, board.value[key]));

    //파일을 처리하기 위한 formData
    let formDataForFile = new FormData(dataForm.value);

    //파일등록을 처리하기 위한 formData
    // Object.keys(formDataForFile).forEach(key => formData.append(key, info.value[key]));
    for (let i = 1; i <= 5; i++) {
      formData.append(`fileId`, formDataForFile.get(`fileId${i}`));
    }

    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');
    axios({
      url: submitUrl,
      method: "POST",
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }).then(res => {
      console.log(res);
      alert("등록이 정상적으로 처리되었습니다.");
      $store.commit('toggleShowStatus', 'dimm');
      $store.commit('toggleShowStatus', 'loading');
      $router.push("/notice/noticeList");
    }).catch(err => {
      console.error(err);
      $store.commit('toggleShowStatus', 'dimm');
      $store.commit('toggleShowStatus', 'loading');
    });
  }
}


onActivated(() => {
  board.value = {};
  setTimeout(() => {
    if (!isLogin.value) {
      $router.push('/');
    } else {
      if ($route.query.IDX) {
        axios({
          url: "/board/seleteBoardDetail.do",
          method: "POST",
          data: { IDX: $route.query.IDX }
        }).then(res => {
          board.value = res.data.board;
        }).catch(err => {
          console.error(err);
        });
      }
    }
  }, 200);
});

</script>