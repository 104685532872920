<template>
  <div class="data_content">
    <div class="table_area">
      <div class="table_box">
        <table>
          <caption>공지사항 목록</caption>
          <colgroup>

          </colgroup>
          <thead>
            <tr>
              <th>번호</th>
              <th>제목</th>
              <th>작성일</th>
              <th>조회수</th>
            </tr>
          </thead>
          <tbody>
            <!-- 240514 추가 -->
            <tr v-if="boardList.length == 0">
                <td colspan="4">- 공지사항이 없습니다. -</td>
            </tr>
            <!-- ///240514 추가 -->


            <tr v-for="board in boardList" :key="board">
              <td>{{ board.IDX }}</td>
              <td>
                <router-link :to="'/notice/noticeDetail/' + board.IDX">{{ board.TITLE }}</router-link>
              </td>
              <td>{{ board.CREA_DTM_STR }}</td>
              <td>{{ board.HIT_CNT }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- 모바일용 -->
    <div class="table_area mobile ">
      <ul>
        <li v-if="boardList.length == 0">
            <a href="javascript:;">
              <h4 style="width: 100%;text-align: center;">- 공지사항이 없습니다 -</h4>
            </a>
        </li>
        <li v-for="board in boardList" :key="board">
          <router-link :to="'/notice/noticeDetail/' + board.IDX">
            <h4>{{ board.TITLE }}</h4>
            <span class="date">{{ board.CREA_DTM_STR }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- ///모바일용 -->
  </div>
  <div class="btn_area more" v-if="isLogin">
    <router-link to="/notice/noticeWrite" class="btn">등록</router-link>
  </div>
  <!-- 페이지네이션 -->
  <PagingList :total-cnt="pageInfo.totalCnt" :total-page="pageInfo.totalPage" @get-page-data="getListData" />

</template>

<script setup>
//import common from '@/assets/js/ui.common.js'
import PagingList from '@/components/PagingList.vue';
import { onMounted, ref, computed, onActivated } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

const $store = useStore();

const boardList = ref([]);
const pageInfo = ref({
  totalCnt: Number,
  totalPage: Number
});

const isLogin = computed(() => {
  return $store.getters.getIsLogin();
});


function fnSearch() {
  getListData(1);
}

function getListData(pageIdx) {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'loading');

  axios({
    url: "/board/seleteBoardList.do",
    method: "POST",
    data: {
      PAGE_INDEX: pageIdx
    }
  }).then(res => {
    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');
    
    boardList.value = res.data.list;
    pageInfo.value = res.data.paging;
  }).catch(err => {
    console.error(err);
  });
}

//defineExpose({ fnSearch })

onMounted(() => {
  fnSearch();
});

onActivated(() => {
  fnSearch();
});

</script>