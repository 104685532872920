<template>
  <div class="data_content">
    <KakaoMap :sido="sido" :buildings="buildings" @build-detail="buildDetail" />
    <BuildDetail v-show="isShowDetail" :building-info="buildingInfo" :subway-list="subwayList" :sales-list="salesList"
      :bd-build-cd="bdBuildCd" @go-detail="goDetail" @close-layer="isShowDetail = false" ref="childRef" />
  </div>
</template>

<script setup>
import KakaoMap from '@/components/KakaoMap.vue';
import BuildDetail from '@/components/BuildDetail.vue';
import { onMounted, onUnmounted, computed, defineExpose, defineProps, ref } from 'vue'
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from 'axios'

const bdBuildCd = ref('01');
const buildings = ref();
const sido = ref();
const buildingInfo = ref();
const subwayList = ref();
const salesList = ref();
const childRef = ref(null);

// eslint-disable-next-line
const salesList2 = ref();


const $store = useStore();
const $router = useRouter();
const isShowFooter = computed(() => {
  return $store.getters.getShowStatus('footer');
});
const isShowDetail = ref(false);
const props = defineProps({
  searchParam: Object
});

function fnSearch() {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'loading');

  //검색 처리 및 맵에 데이터 넣기..
  axios({
    url: "/sub/getSido.do",
    method: "POST",
    data: props.searchParam
  }).then(res => {
    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');
    buildings.value = res.data.buildings;
    sido.value = res.data.sido.map((o) => {
      o.PRD_CNT = o.B_CNT;
      return o;
    });
  }).catch(err => {
    console.error(err);
  });
}

function buildDetail(building) {
  if (building.BD_ID == 999999999) {
    goDetail(building);
    return;
  }

  isShowDetail.value = false;
  buildingInfo.value = null;
  subwayList.value = null;
  salesList.value = null;

  const mapParam = {
    BD_BUILD_CD: props.searchParam.BD_BUILD_CD,
    BD_AREA_CD: props.searchParam.BD_AREA_CD,
    BD_EXE_AREA: props.searchParam.BD_EXE_AREA,
    BD_PRICE: props.searchParam.BD_PRICE,
    VIEW_3D_YN: props.searchParam.VIEW_3D_YN,
    BD_ID: building.BD_ID,
    BY_ID: building.BY_ID
  };

  axios({
    url: "/sub/maps/lists.do",
    method: "POST",
    data: mapParam
  }).then(res => {
    isShowDetail.value = true;
    buildingInfo.value = res.data.info;
    subwayList.value = res.data.subList;
    salesList.value = res.data.infolist;

    let cnt = 0;
    salesList.value.map((o) => {
      if (bdBuildCd.value == o.BD_BUILD_CD) {
        cnt++;
      }
    });
    childRef.value.changeBuildingType(cnt == 0);
  }).catch(err => {
    console.error(err);
  });
}

function goDetail(info) {
  $router.push('/parcel/parcelDetail/' + info.BY_ID);
}

defineExpose({ fnSearch })

onMounted(() => {
  if (isShowFooter.value) {
    $store.commit('toggleShowStatus', 'footer');
  }
  fnSearch();
});
onUnmounted(() => {
  if (!isShowFooter.value) {
    $store.commit('toggleShowStatus', 'footer');
  }
});

</script>
