<template>
  <div class="login_content">
    <div class="login_box">
      <h1 class="login_logo">
        <img src="@/assets/images/logo.png" alt="로고">
      </h1>
      <form method="POST" id="loginFrm" name="frm" class="frm loginFrm">
        <div class="input elogin">
          <!-- <label for="userId" class="label">아이디</label> -->
          <input id="userId" type="text" v-model="userId" placeholder="아이디">
        </div>
        <div class="input elogin input_pw">
          <!-- <label for="userPw" class="label">비밀번호</label> -->
          <input id="userPw" type="password" v-model="userPw" placeholder="비밀번호" @keydown.enter.prevent="goLogin">
        </div>
        <a href="javascript:" class="btn" @click="goLogin">로그인</a>
      </form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import axios from 'axios'

const $router = useRouter();
const $store = useStore();
const isShowFooter = computed(() => {
  return $store.getters.getShowStatus('footer');
});
// const isLogin = computed(() => {
//   return $store.getters.getIsLogin();
// });
const userId = ref();
const userPw = ref();

function goLogin() {
  //실제 로그인 처리 구현 필요
  axios({
    url: "/sub/login.do",
    method: "POST",
    data: {
      'U_ID': userId.value,
      'U_PASSWD': userPw.value
    }
    // eslint-disable-next-line
  }).then(res => {
    let isLogin = !!res.data.isLogin;
    if(isLogin) {
      $store.dispatch('dispatchLogin');
      $router.push("/");
    } else {
      alert('아이디나 비밀번호가 올바르지 않습니다.');
    }
  }).catch(err => {
    console.error(err);
  });
}

onMounted(() => {
  if (isShowFooter.value) {
    $store.commit('toggleShowStatus', 'footer');
  }
});
onUnmounted(() => {
  if (!isShowFooter.value) {
    $store.commit('toggleShowStatus', 'footer');
  }
});

</script>

<style scoped>
.wrap {
  position: relative;
}

.login_content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login_logo {
  width: 120px;
  margin: 0 auto 40px;
}

.loginFrm {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.loginFrm input {
  width: 100%;
  border: 1px solid #E4EAF4;
  outline: none;
  font-size: 16px;
  color: #000;
  padding: 8px 10px;
}

.loginFrm .btn {
  outline: none;
  display: inline-block;
  border: none;
  padding: 14px 58px;
  margin-top: 20px;
  font-size: 16px;
  background-color: #333;
  border-radius: 2px;
  color: #fff;
  text-align: center;
  font-family: "INNODAOOM";
}

@media screen and (max-width: 768px) {
  .login_content {
    width: 80%;
  }

  .loginFrm {
    width: 100%;
  }
}
</style>