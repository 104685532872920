<template>
    <div class="content main"> <!-- 메인에만 클래스 main추가 -->
        <!-- 타이틀 / 버튼 상단 영역 -->
        <div class="top_area">
            <div class="top_wrap">
                <div class="t_content">
                    <div id="tempApp"></div>
                    <p class="title_box">
                        <!-- <strong>(주)공장리더스</strong> 는요<br>성남 상대원 소재 지식산업센터 낸 입점해 있는 공장 전문 부동산입니다.<br>
                        성남 상대원 토박이인 대표 양유선이 근 14년간 이곳에서 중개업을 운영하고 있습니다.<br>
                        편안한 발걸음으로 찾아주셔서 문의해 주세요. -->
                        <strong>" 약속 중시ㆍ정직함ㆍ책임감 "<br></strong>
                        <br>
                        <em>지식산업센터ㆍ일반공장ㆍ토지ㆍ창고ㆍ상가ㆍ기숙사</em><br>
                        <span>매물접수&nbsp;매물상담&nbsp;010 9266 1446</span>
                    </p>
                    <div class="middle_box">
                        <!-- 240711 블로그 바로가기 링크 추가 -->
                        <div class="total_number">
                            <!-- <em>{{ totalCnt }}+</em>총 매물수 -->
                             <a href="https://blog.naver.com/basragy" target="_blank" title="네이버 블로그 바로가기"></a>
                        </div>
                        <div class="btn_area">
                            <router-link to="/trading/tradingMap" class="btn ico02">매매</router-link>
                            <router-link to="/lease/leaseMap" class="btn ico03">임대</router-link>
                            <router-link to="/parcel/parcelMap" class="btn ico01">분양</router-link>
                        </div>
                    </div>
                    <div class="form_box">
                        <form id="searchForm" class="search_form" action="">
                            <div class="serach_box">
                                <div class="s_txt">
                                    <button type="button" class="btn_search" @click="totalSearch"></button>
                                    <input type="text" class="search_txt" v-model="searchNm"
                                        placeholder="건물명, 도로명주소, 지번주소, 지하철역" value=""
                                        @keydown.enter.prevent="totalSearch">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="img_map"></div>
            </div>
        </div>
        <!-- 데이터 박스 -->
        <div class="data_box">
            <div class="data_content">
                <div class="title_area">
                    <h2>추천매물정보</h2>
                    <span>실시간 추천 매물 정보를 한곳에!</span>
                </div>
                <div class="swiper_area">
                    <div class="swiper-container item_list">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="info in recommendList" :key="info">
                                <a href="javascript:" @click="fnView(info)">
                                    <dl>
                                        <dt>
                                            <img :src="'/image/' + info.FILE_ID1 + '/view.do'"
                                                @error="common.replaceDefaultImage">
                                            <span class="sale_price" v-if="info.BD_BUILD_CD == '01'">{{
                                common.comma(info.BD_PRICE / 10000) }} 만원</span>
                                            <span class="sale_price"
                                                v-else-if="info.BD_BUILD_CD == '02' || info.BD_BUILD_CD == '04'">{{
                                common.comma(info.BD_PRICE / 10000) }} 만원</span>
                                            <span class="sale_price" v-else-if="info.BD_BUILD_CD == '03'">{{
                                common.comma(info.BD_DEPOSIT / 10000) }} 만원 / 월 {{
                                common.comma(info.BD_MON_RENT / 10000) }} 만원</span>
                                        </dt>
                                        <dd>
                                            <h3>{{ info.BD_NM }}</h3>
                                            <span class="lineClamp">
                                                {{ info.BD_ADDR ? info.BD_ADDR : info.BD_JIBUN_ADDR }}
                                                {{ info.BD_FLOOR != null ? info.BD_FLOOR.replaceAll("층", "") + "층" : ""
                                                }}
                                                계약 {{ info.BD_APP_AREA }} ㎡ / 전용 {{ info.BD_EXE_AREA }} ㎡
                                            </span>
                                        </dd>
                                    </dl>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                </div>
                <!-- <div class="btn_area more" v-show="isMoreRecommendList">
                    <a href="javascript:;" class="btn" @click="getRecommendList">더보기</a>
                </div> -->
            </div>
            <div class="data_content">
                <div class="title_area">
                    <h2>최신매물정보</h2>
                    <span>실시간 추천 매물 정보를 한곳에!</span>
                </div>
                <div class="swiper_area">
                    <div class="swiper-container item_list item_list02">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="info in salesList" :key="info">
                                <a href="javascript:" @click="fnView(info)">
                                    <dl>
                                        <dt>
                                            <img :src="'/image/' + info.FILE_ID1 + '/view.do'"
                                                @error="common.replaceDefaultImage">
                                            <span class="sale_price" v-if="info.BD_BUILD_CD == '01'">{{
                                common.comma(info.BD_PRICE / 10000) }} 만원</span>
                                            <span class="sale_price"
                                                v-else-if="info.BD_BUILD_CD == '02' || info.BD_BUILD_CD == '04'">{{
                                common.comma(info.BD_PRICE / 10000) }} 만원</span>
                                            <span class="sale_price" v-else-if="info.BD_BUILD_CD == '03'">{{
                                common.comma(info.BD_DEPOSIT / 10000) }} 만원 / 월 {{
                                common.comma(info.BD_MON_RENT / 10000) }} 만원</span>
                                        </dt>
                                        <dd>
                                            <h3>{{ info.BD_NM }}</h3>
                                            <span class="lineClamp">
                                                {{ info.BD_ADDR ? info.BD_ADDR : info.BD_JIBUN_ADDR }}
                                                {{ info.BD_FLOOR != null ? info.BD_FLOOR.replaceAll("층", "") + "층" : ""
                                                }}
                                                계약 {{ info.BD_APP_AREA }} ㎡ / 전용 {{ info.BD_EXE_AREA }} ㎡
                                            </span>
                                        </dd>
                                    </dl>
                                </a>
                            </div>
                        </div>
                        <!-- <div class="swiper-button-next"></div>
                                        <div class="swiper-button-prev"></div> -->
                    </div>
                    <div class="swiper-button-next swiper-button-next02"></div>
                    <div class="swiper-button-prev swiper-button-prev02"></div>
                </div>
            </div>
        </div>
        <!-- ///데이터 박스 -->
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import axios from 'axios'
import common from '@/assets/js/ui.common.js'
import Swiper from '@/assets/js/swiper.min.js'

const $router = useRouter();
const searchNm = ref();
const recommendList = ref();
const pageInfo = ref();
const salesList = ref();
const totalCnt = ref(0);
let pageIdx = 0;
// const isMoreRecommendList = computed(() => {
//     let rtnValue = false;
//     if(pageInfo.value) {
//         rtnValue = pageInfo.value.totalPage > pageIdx;
//     }
//     return rtnValue;
// });

function totalSearch() {
    $router.push({ path: '/total/totalMap', query: { 'SEARCH_NM': searchNm.value } });
}

function fnView(info) {
    let path = '';
    switch (info.BD_BUILD_CD) {
        case "01": path = '/parcel/parcelDetail/' + info.BY_ID; break;
        case "02": path = '/trading/tradingDetail/' + info.BY_ID; break;
        case "03": path = '/lease/leaseDetail/' + info.BY_ID; break;
    }
    if (path != '') {
        $router.push(path);
    }
}

function getRecommendList() {
    //추천매물 조회
    axios({
        url: "/sub/seleteSaleList.do",
        method: "POST",
        data: {
            "PAGE_INDEX": ++pageIdx,
            "RECOMMEND_YN": "Y",
            "DISP_YN": 'Y'
        }
    }).then(res => {
        if (recommendList.value == null) {
            recommendList.value = res.data.list;
        } else {
            recommendList.value.push(...res.data.list);
        }
        pageInfo.value = res.data.paging;
    }).catch(err => {
        console.error(err);
    });
}

watch(
    () => recommendList.value,
    () => {
        setTimeout(function () {
            new Swiper(".item_list", {
                slidesPerView: 5,
                spaceBetween: 16,
                allowTouchMove: true,
                autoplay: {     //자동슬라이드 (false-비활성화)
                    delay: 2500, // 시간 설정
                    disableOnInteraction: false, // false-스와이프 후 자동 재생
                },
                loop: false,   // 슬라이드 반복 여부
                loopAdditionalSlides: 1,
                // 슬라이드 반복 시 마지막 슬라이드에서 다음 슬라이드가 보여지지 않는 현상 수정
                // pagination: { // 호출(pager) 여부
                //     el: ".swiper-pagination", //버튼을 담을 태그 설정
                //     clickable: true, // 버튼 클릭 여부
                // },
                breakpoints: {
                    500: {
                        slidesPerView: 2,  //브라우저가 500보다 클 때
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,  //브라우저가 768보다 클 때
                        spaceBetween: 10,
                    },
                    //1024: {
                    //slidesPerView: 3,  //브라우저가 1024보다 클 때
                    //spaceBetween: 10,
                    //},
                    1300: {
                        slidesPerView: 4,  //브라우저가 1024보다 클 때
                        spaceBetween: 10,
                    },
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                    clickable: true,
                },
            });


        }, 1);
    }
);

watch(
    () => salesList.value,
    () => {
        setTimeout(function () {
            new Swiper(".item_list.item_list02", {
                slidesPerView: 5,
                spaceBetween: 16,
                allowTouchMove: true,
                autoplay: {     //자동슬라이드 (false-비활성화)
                    delay: 2500, // 시간 설정
                    disableOnInteraction: false, // false-스와이프 후 자동 재생
                },
                loop: false,   // 슬라이드 반복 여부
                loopAdditionalSlides: 1,
                // 슬라이드 반복 시 마지막 슬라이드에서 다음 슬라이드가 보여지지 않는 현상 수정
                // pagination: { // 호출(pager) 여부
                //     el: ".swiper-pagination", //버튼을 담을 태그 설정
                //     clickable: true, // 버튼 클릭 여부
                // },
                breakpoints: {
                    500: {
                        slidesPerView: 2,  //브라우저가 500보다 클 때
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 2,  //브라우저가 768보다 클 때
                        spaceBetween: 10,
                    },
                    //1024: {
                    //slidesPerView: 3,  //브라우저가 1024보다 클 때
                    //spaceBetween: 10,
                    //},
                    1300: {
                        slidesPerView: 4,  //브라우저가 1024보다 클 때
                        spaceBetween: 10,
                    },
                },
                navigation: {
                    nextEl: ".swiper-button-next02",
                    prevEl: ".swiper-button-prev02",
                    clickable: true,
                },
            });


        }, 1);
    }
);

onMounted(() => {
    //전체건수 및 매물리스트 조회
    axios({
        url: "/sub/seleteSaleList.do",
        method: "POST",
        data: {
            "PAGE_INDEX": 1,
            "DISP_YN": 'Y'
        }
    }).then(res => {
        salesList.value = res.data.list;
        totalCnt.value = res.data.paging.totalCnt;
    }).catch(err => {
        console.error(err);
    });

    getRecommendList();

});
</script>