<template>
  <div class="content board"> <!-- 게시판에만 클래스 board추가 -->
    <!-- 타이틀 -->
    <div class="sub_top_area">
      <div class="top_wrap">
        <div class="t_content">
          <h3>매매/임대 등록</h3>
        </div>
      </div>
    </div>
    <!-- ///타이틀 -->
    <!-- 데이터 박스 -->
    <div class="data_box">
      <div class="data_content">
        <div class="rgstr">
          <form ref="dataForm" id="frm" name="frm" class="rgstr_form" enctype="multipart/form-data">
            <!-- <input type="hidden" name="CENT_ID" id="CENT_ID" value="${info.CENT_ID }" />
            <input type="hidden" name="BY_ID" id="BY_ID" value="${info.BY_ID }" /> -->
            <ul>
              <li>
                <div class="flex_box" style="justify-content: end;padding-bottom: 20px">
                  <div class="search_option_box">
                    <div class="check_box">
                      <label class="chk_label">
                        <input type="checkbox" v-model="isFactory" />
                        <i class="xi-check"></i>
                        <span>수기등록여부</span>
                      </label>
                    </div>
                  </div>
                </div>
              </li>              
              <li>
                <div class="flex_box">
                  <span>거래형태</span>
                  <div class="select_box">
                    <div class="s_txt">
                      <select v-model="info.BD_BUILD_CD" class="select_list" @change="clearBdList">
                        <option value="">구분</option>
                        <option value="01">분양</option>
                        <option value="02">매매</option>
                        <option value="04">분양권매매</option>
                        <option value="03">임대</option>
                      </select>
                      <span class="arrow"><i class="xi-angle-down"></i></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>중개대상물의 종류</span>
                  <div class="select_box">
                    <div class="s_txt">
                      <select v-model="info.BUILD_TYPE" class="select_list">
                        <option value="">구분</option>
                        <option value="01">지식산업센터</option>
                        <option value="02">근린상가</option>
                        <option value="03">업무시설</option>
                        <option value="04">기숙사</option>
                        <option value="05">창고</option>
                        <option value="06">일반공장</option>
                        <option value="07">토지</option>
                      </select>
                      <span class="arrow"><i class="xi-angle-down"></i></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span v-if="isFactory">매물명(건물명)</span>
                  <span v-else>건물명</span>
                  <div class="input_box" v-if="isFactory">
                    <input type="text" class="group" v-model="info.BD_NM">
                  </div>
                  <div class="input_box" v-else>
                    <input type="text" class="group" placeholder="건물명 일부만 입력하시면 됩니다." v-model="info.BD_NM"
                      @keydown.enter.prevent="fnFindBd">
                    <a href="javascript:;" class="btn" @click="fnFindBd">검색</a>
                    <div class="select_box" v-if="bdList.length > 0">
                      <div class="s_txt">
                        <select v-model="info.BD_ID" class="select_list" @change="setInfoData">
                          <option value=''>건물을 선택하세요</option>
                          <template v-for="bd in bdList" :key="bd">
                            <option v-if="bd.BD_ID" :value="bd.BD_ID">{{ bd.BD_NM }}</option>
                          </template>
                        </select>
                        <span class="arrow"><i class="xi-angle-down"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <!-- 240509 추가 -->
              <li v-if="isFactory">
                <div class="flex_box">
                  <span>지역코드(시도/구군)</span>
                  <div class="input_box flex_box">
                    <div class="select_box">
                      <div class="s_txt">
                        <select class="select_list" v-model="info.BD_AREA_CD" @change="info.BD_SIDO_CD = ''">
                          <option value="">시/도를 선택하세요</option>
                          <option v-for="areaCd in areaCdList" :key="areaCd" :value="areaCd.SIDO_ID">{{ areaCd.SIDO_NAME
                            }}
                          </option>
                        </select>
                        <span class="arrow"><i class="xi-angle-down"></i></span>
                      </div>
                    </div>
                    <div class="select_box">
                      <div class="s_txt">
                        <select class="select_list" v-model="info.BD_SIDO_CD">
                          <option value="">구/군을 선택하세요</option>
                          <option v-for="subAreaCd in subAreaCdList" :key="subAreaCd" :value="subAreaCd.SIDO_ID">{{
                        subAreaCd.SIDO_NAME }}</option>
                        </select>
                        <span class="arrow"><i class="xi-angle-down"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="bdAddrLi">
                <div class="flex_box">
                  <span>도로명주소</span>
                  <div class="input_box">
                    <input type="text" v-model="info.BD_ADDR" v-if="isFactory">
                    <input type="text" v-model="info.BD_ADDR" placeholder="건물을 선택해주세요." disabled v-else>
                    <a href="javascript:;" class="btn" v-if="isFactory" @click="addressSearch">좌표변환</a>
                    <!-- 240509 추가 / 특정대상물일경우만 보이게 -->
                  </div>
                </div>
              </li>
              <!-- 240509 추가 -->
              <li v-if="isFactory">
                <div class="flex_box">
                  <span>위도</span>
                  <div class="input_box">
                    <div class="flex_box">
                      <input type="number" v-model="info.LOAD_LAT">
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="isFactory">
                <div class="flex_box">
                  <span>경도</span>
                  <div class="input_box">
                    <div class="flex_box">
                      <input type="number" v-model="info.LOAD_LNG">
                    </div>
                  </div>
                </div>
              </li>
              <!-- ///240509 추가 -->
              <li v-if="!isFactory">
                <div class="flex_box">
                  <span>지번주소</span>
                  <div class="input_box">
                    <input type="text" v-model="info.BD_JIBUN_ADDR" placeholder="건물을 선택해주세요." disabled
                      v-show="info.BD_JIBUN_ADDR != ''">
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>해당동/층/호</span>
                  <div class="insert_wrap">
                    <div class="insert_area" v-show="info.BD_DONG_TYPE == 1">
                      <div class="inpBtnGroup  type2">
                        <input type="text" v-model="info.BD_DONG" class="inpTxt tar" />
                        <span class="unt">동</span>
                      </div>
                      <div class="inpBtnGroup  type2">
                        <input type="text" v-model="info.BD_FLOOR" class="inpTxt tar" />
                        <span class="unt">층</span>
                      </div>
                      <div class="inpBtnGroup  type2">
                        <input type="text" v-model="info.BD_HO" class="inpTxt tar" />
                        <span class="unt">호</span>
                      </div>
                    </div>
                    <div class="insert_area" v-show="info.BD_DONG_TYPE == 2">
                      <div class="select_box">
                        <div class="s_txt">
                          <select class="select_list" v-model="info.BD_DONG" @change="dongChange">
                            <option value="">동</option>
                            <option v-for="dong in currDongList" :key="dong" :value="dong.dongName">{{ dong.dongName }}
                            </option>
                          </select>
                          <span class="arrow"><i class="xi-angle-down"></i></span>
                        </div>
                      </div>
                      <div class="select_box">
                        <div class="s_txt">
                          <select class="select_list" v-model="info.BD_FLOOR" @change="floorChange">
                            <option value="">층</option>
                            <option v-for="floor in currFloorList" :key="floor" :value="floor.FLOOR_NM">{{
                        floor.FLOOR_NM
                      }}
                            </option>
                          </select>
                          <span class="arrow"><i class="xi-angle-down"></i></span>
                        </div>
                      </div>
                      <div class="select_box">
                        <div class="s_txt">
                          <select class="select_list" v-model="info.BD_HO" @change="roomChange">
                            <option value="">호</option>
                            <option v-for="room in currRoomList" :key="room" :value="room.ROOM_NM">{{ room.ROOM_NM
                              }}
                            </option>
                          </select>
                          <span class="arrow"><i class="xi-angle-down"></i></span>
                        </div>
                      </div>
                    </div>
                    <div class="radio_box" v-show="!isFactory">
                      <label class="radio_label" for="BD_DONG_TYPE1"><input type="radio" id="BD_DONG_TYPE1"
                          v-model="info.BD_DONG_TYPE" value="1"><i class="radio_check"></i><span>직접입력</span></label>
                      <label class="radio_label" for="BD_DONG_TYPE2"><input type="radio" id="BD_DONG_TYPE2"
                          v-model="info.BD_DONG_TYPE" value="2"><i class="radio_check"></i><span>자동입력</span></label>
                    </div>
                    <span v-show="!isFactory">* 자동입력하여도 매물정보에는 ‘층’만 표시됩니다.</span>
                  </div>

                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>총층수</span>
                  <div class="input_box">
                    <input type="text" v-model="info.FLOOR_TXT">
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>연락처</span>
                  <div class="input_box" style="display: flex;gap: 20px">
                    <input type="text" v-model="CONTACT_TEL_0" maxlength="4" limitlength="3">
                    <input type="text" v-model="CONTACT_TEL_1" maxlength="4" limitlength="4">
                    <input type="text" v-model="CONTACT_TEL_2" maxlength="4" limitlength="4">
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>입주가능일</span>
                  <div class="select_box">
                    <div class="s_txt">
                      <select v-model="info.MOVE_INTO_DT" class="select_list">
                        <option v-for="moveIntoDtCd in moveIntoDtCdList" :key="moveIntoDtCd"
                          :value="moveIntoDtCd.dtlCodeId">
                          {{ moveIntoDtCd.dtlCodeNm }}</option>
                      </select>
                      <span class="arrow"><i class="xi-angle-down"></i></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>사용승인일</span>
                  <div class="input_box">
                    <input type="text" v-model="info.USE_APP_YMD">
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>공급면적</span>
                  <div class="input_box group">
                    <input v-show="isAppSqm" type="number" v-model="info.BD_APP_AREA" class="group apiInfo"
                      :readonly="info.BD_DONG_TYPE == 2">
                    <input v-show="!isAppSqm" type="number" :value="(Number(info.BD_APP_AREA) / 3.306).toFixed(1)"
                      class="group apiInfo" :readonly="info.BD_DONG_TYPE == 2">
                    <a href="javascript:;" class="btn" @click="isAppSqm = !isAppSqm">단위변환</a>
                    <span v-show="isAppSqm" class="unt">㎡</span>
                    <span v-show="!isAppSqm" class="unt">py</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>전용면적</span>
                  <div class="input_box group" id="sqmExeArea">
                    <input v-show="isExeSqm" type="number" v-model="info.BD_EXE_AREA" class="group apiInfo"
                      :readonly="info.BD_DONG_TYPE == 2">
                    <input v-show="!isExeSqm" type="number" :value="(Number(info.BD_EXE_AREA) / 3.306).toFixed(1)"
                      class="group apiInfo" :readonly="info.BD_DONG_TYPE == 2">
                    <a href="javascript:;" class="btn" @click="isExeSqm = !isExeSqm">단위변환</a>
                    <span v-show="isExeSqm" class="unt">㎡</span>
                    <span v-show="!isExeSqm" class="unt">py</span>
                  </div>
                </div>
              </li>
              <li v-show="info.BD_BUILD_CD == '01'">
                <div class="flex_box">
                  <span>총 분양가</span>
                  <div class="input_box">
                    <input type="number" v-model="info.BD_PRICE" class="group">
                    <span class="unt">만원</span>
                  </div>
                </div>
              </li>
              <li v-show="info.BD_BUILD_CD == '02' || info.BD_BUILD_CD == '04'">
                <div class="flex_box">
                  <span>총 매매가</span>
                  <div class="input_box">
                    <input type="number" v-model="info.BD_PRICE" class="group">
                    <span class="unt">만원</span>
                  </div>
                </div>
              </li>
              <li v-show="info.BD_BUILD_CD == '03'">
                <div class="flex_box">
                  <span>보증금</span>
                  <div class="input_box">
                    <input type="number" v-model="info.BD_DEPOSIT" class="group">
                    <span class="unt">만원</span>
                  </div>
                </div>
              </li>
              <li v-show="info.BD_BUILD_CD == '03'">
                <div class="flex_box">
                  <span>월임대료</span>
                  <div class="input_box">
                    <input type="number" v-model="info.BD_MON_RENT" class="group">
                    <span class="unt">만원</span>
                  </div>
                  <p class="ipMsg">(py당임대료/3.3m2) <span id="bdCount"></span>원</p>
                  <input type="hidden" id="BD_BAL_PRICE" name="BD_BAL_PRICE">
                </div>
              </li>
              <li v-show="info.BD_BUILD_CD == '02' || info.BD_BUILD_CD == '03'">
                <div class="flex_box">
                  <span>py당관리비</span>
                  <div class="input_box">
                    <input type="number" v-model="info.MAIN_COST" class="group">
                    <span class="unt">원</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>냉난방</span>
                  <div class="radio_box" style="padding: 10px 0;">
                    <label class="radio_label" for="BD_HEAT1">
                      <input type="radio" id="BD_HEAT1" v-model="info.BD_HEAT" value="중앙난방">
                      <i class="radio_check"></i>
                      <span>중앙난방</span>
                    </label>
                    <label class="radio_label" for="BD_HEAT2">
                      <input type="radio" id="BD_HEAT2" v-model="info.BD_HEAT" value="개별난방">
                      <i class="radio_check"></i>
                      <span>개별난방</span>
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>주차대수</span>
                  <div class="input_box">
                    <input type="text" v-model="info.BD_INNER_CAR">
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>방향</span>
                  <div class="select_box">
                    <div class="s_txt">
                      <select v-model="info.BD_DIRECTION" class="select_list">
                        <option value="">선택</option>
                        <option value="01">동향(발코니)</option>
                        <option value="02">서향(발코니)</option>
                        <option value="03">남향(발코니)</option>
                        <option value="04">북향(발코니)</option>
                        <option value="05">북동향(발코니)</option>
                        <option value="06">남동향(발코니)</option>
                        <option value="07">남서향(발코니)</option>
                        <option value="08">북서향(발코니)</option>
                      </select>
                      <span class="arrow"><i class="xi-angle-down"></i></span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>옵션정보</span>
                  <div class="search_option_box">
                    <div class="check_box" v-for="bdOptionCd in bdOptionCdList" :key="bdOptionCd">
                      <label class="chk_label">
                        <input type="checkbox" :id="bdOptionCd.dtlCodeId" v-model="optionCodes"
                          :value="bdOptionCd.dtlCodeId" />
                        <i class="xi-check"></i>
                        <span>{{ bdOptionCd.dtlCodeNm }}</span>
                      </label>
                    </div>
                    <input type="text" class="inpTxt" v-model="info.BD_OPTION_TEXT">
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>첨부이미지</span>
                  <div class="file_input_box">
                    <span> * 1920 X 1080 (비율) 가로이미지 추천</span>
                    <template v-for="idx in 30" :key="idx">
                      <div class="file_input_wrap">
                        <input type="file" :name="`fileId${idx}`" :id="'fileId' + idx" class="flie_input"
                          @change="onFileChange($event)" />
                        <ul>
                          <li>
                            <input type="text" class="file_name" :value="info['fileId' + idx]" readonly />
                          </li>
                        </ul>
                        <label :for="'fileId' + idx" class="btn sub_btn">파일첨부</label>
                      </div>
                      <div class="itemImg" v-if="info['FILE_ID' + idx]">
                        <div class="chooseBtn">
                          <div class="formGroup">
                            <span class="item">
                              <input type="checkbox" :name="'FILE_ID' + idx + '_DEL'" :id="'FILE_ID' + idx + '_DEL'"
                                value="Y" v-model="info['FILE_DEL_' + idx]"><label
                                :for="'FILE_ID' + idx + '_DEL'">삭제</label>
                            </span>
                          </div>
                        </div>
                        <div class="img">
                          <img :src="'/image/' + info['FILE_ID' + idx] + '/view.do'" alt="" />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>매물특징</span>
                  <div class="input_box">
                    <textarea v-model="info.BD_FEATURE" class="txt_area"></textarea>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>3D URL</span>
                  <div class="input_box">
                    <input type="text" v-model="info.MATTER_PORT_URL">
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>추천매물여부</span>
                  <div class="radio_box" style="padding: 10px 0;">
                    <label class="radio_label" for="RECOMMEND_Y">
                      <input type="radio" id="RECOMMEND_Y" v-model="info.RECOMMEND_YN" value="Y">
                      <i class="radio_check"></i>
                      <span>Y</span>
                    </label>
                    <label class="radio_label" for="RECOMMEND_N">
                      <input type="radio" id="RECOMMEND_N" v-model="info.RECOMMEND_YN" value="N">
                      <i class="radio_check"></i>
                      <span>N</span>
                    </label>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex_box">
                  <span>표시여부</span>
                  <div class="radio_box" style="padding: 10px 0;">
                    <label class="radio_label" for="DISP_Y">
                      <input type="radio" id="DISP_Y" v-model="info.DISP_YN" value="Y">
                      <i class="radio_check"></i>
                      <span>Y</span>
                    </label>
                    <label class="radio_label" for="DISP_N">
                      <input type="radio" id="DISP_N" v-model="info.DISP_YN" value="N">
                      <i class="radio_check"></i>
                      <span>N</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>

      <div class="btn_area">
        <a href="javascript:;" class="btn" @click="goList">목록</a>
        <a href="javascript:;" class="btn save" @click="fnSubmit">저장</a>
      </div>
    </div>
    <!-- ///데이터 박스 -->
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex";
import axios from 'axios'

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const moveIntoDtCdList = computed(() => {
  return $store.getters.getCommonCodeList('F01');
});
const bdOptionCdList = computed(() => {
  return $store.getters.getCommonCodeList('CM002');
});
const isLogin = computed(() => {
  return $store.getters.getIsLogin();
});

const info = ref({
  BD_BUILD_CD: '',
  BUILD_TYPE: '',
  BD_DIRECTION: '',
  MOVE_INTO_DT: 'F001',
  BD_DONG_TYPE: 2,
  BD_OPTION_CODES: '',
  CONTACT_TEL: '--',
  RECOMMEND_YN: 'N',
  DISP_YN: 'Y',
  MATTER_PORT_URL: '',
  BD_AREA_CD: '',
  BD_SIDO_CD: ''
});

const bdList = ref([]);
const currDongList = ref([]);
const currFloorList = ref([]);
const currRoomList = ref([]);
const isAppSqm = ref(true);
const isExeSqm = ref(true);
const isFactory = ref(false);

const areaCdList = computed(() => {
  return $store.getters.getSidoList('ROOT');
});
const subAreaCdList = computed(() => {
  return $store.getters.getSidoList(info.value.BD_AREA_CD);
});

const optionCodes = computed({
  get: () => {
    return info.value.BD_OPTION_CODES ? info.value.BD_OPTION_CODES.split(',') : [];
  },
  set: (val) => {
    return info.value.BD_OPTION_CODES = val.join(',');
  }
});

const CONTACT_TEL_0 = computed({
  get: () => {
    return info.value.CONTACT_TEL ? info.value.CONTACT_TEL.split('-')[0] : '';
  },
  set: (val) => {
    return info.value.CONTACT_TEL = `${val}-${CONTACT_TEL_1.value}-${CONTACT_TEL_2.value}`;
  }
});
const CONTACT_TEL_1 = computed({
  get: () => {
    return info.value.CONTACT_TEL ? info.value.CONTACT_TEL.split('-')[1] : '';
  },
  set: (val) => {
    return info.value.CONTACT_TEL = `${CONTACT_TEL_0.value}-${val}-${CONTACT_TEL_2.value}`;
  }
});
const CONTACT_TEL_2 = computed({
  get: () => {
    return info.value.CONTACT_TEL ? info.value.CONTACT_TEL.split('-')[2] : '';
  },
  set: (val) => {
    return info.value.CONTACT_TEL = `${CONTACT_TEL_0.value}-${CONTACT_TEL_1.value}-${val}`;
  }
});


const dataForm = ref(null);

function fnFindBd() {
  if (info.value.BD_BUILD_CD == '') {
    alert('거래형태를 먼저 선택해주세요.');
    return;
  }

  let searchNm = info.value.BD_NM;
  if (searchNm && searchNm.length > 0) {
    axios({
      url: "/sub/getBuildListAjax.do",
      method: "POST",
      data: {
        'BD_NM': searchNm,
        'SALE_YN': info.value.BD_BUILD_CD == '01' ? 'Y' : 'N'
      }
    }).then(res => {
      if (res.data.list.length > 0) {
        bdList.value = res.data.list.map((bd) => {
          let parkingStr = "";
          if (bd.BD_INNER_CAR != null) {
            parkingStr = "옥내" + bd.BD_INNER_CAR;
          }
          if (bd.BD_OUTER_CAR != null) {
            parkingStr += "/옥외" + bd.BD_OUTER_CAR;
          }
          bd.parkingStr = parkingStr;
          return bd;
        });
        info.value.BD_ID = '';
      } else {
        alert("검색결과가 없습니다.");
      }
    }).catch(err => {
      console.error(err);
    });
  } else {
    alert("검색어를 입력해주세요.");
  }
}

function clearBdList() {
  bdList.value = [];
  info.value.BD_ID = "";
}

function setInfoData() {
  if (bdList.value.length > 0) {
    const findBd = bdList.value.find((bd) => {
      return bd.BD_ID == info.value.BD_ID;
    });
    if (findBd) {
      info.value.BD_ADDR = findBd.BD_ADDR;
      info.value.BD_JIBUN_ADDR = findBd.BD_JIBUN_ADDR;
      info.value.CENT_ID = findBd.CENT_ID;
      info.value.BD_INNER_CAR = findBd.parkingStr;
      info.value.USE_APP_YMD = findBd.BD_AUTH_DT;

      info.value.BD_DONG = '';
      info.value.BD_FLOOR = '';
      info.value.BD_HO = '';
      currDongList.value = [];
      currFloorList.value = [];
      currRoomList.value = [];
      getDongList(info.value.BD_ID);
    }
  }
}

function getDongList(bdId) {
  if (!bdId) {
    return;
  }
  axios({
    url: "/sub/getDongList.do",
    method: "POST",
    data: { 'BD_ID': bdId }
  }).then(res => {
    const dongInfo = res.data.dongInfo;
    const dongName = res.data.dongInfo.BD_NM;
    const dongList = res.data.dongList;
    if (dongList.length > 0) {
      //임대,매매인경우
      currDongList.value = dongList.map((dong) => {
        if (dong.DONG_NM && dong.DONG_NM.trim() != "") {
          dong.dongName = dong.DONG_NM;
        } else {
          dong.dongName = dongName;
        }
        //dong.dongHtml = 
        dong.dongText = (dongList.length > 1 ? dong.DONG_NM : '') + "  지상 " + dong.BD_OVER_FLOOR + (dong.BD_UNDER_FLOOR != null ? " / 지하" + dong.BD_UNDER_FLOOR : '');
        dong.dongId = dong.BD_DONG_ID;
        dong.saleYn = "N";
        return dong;
      });
    } else {
      // 분양인 경우
      currDongList.value = [{
        dongName: dongName,
        dongText: "지상 " + dongInfo.BD_GF_CNT + " / 지하 " + dongInfo.BD_UF_CNT,
        dongId: dongInfo.SALE_ID,
        saleYn: "Y"
      }];
    }
    if (currDongList.value.length == 1) {
      info.value.FLOOR_TXT = currDongList.value[0].dongText;
    }
    // 선택된 동이 있을경우
    if (info.value.BD_DONG) {
      dongChange();
    }
  }).catch(err => {
    console.error(err);
  });
}

function dongChange() {
  const selDong = currDongList.value.find((dong) => {
    return info.value.BD_DONG == dong.dongName;
  });
  info.value.FLOOR_TXT = selDong.dongText;
  getFloorList(selDong.dongId, selDong.saleYn);
}

function getFloorList(dongId, saleYn) {
  axios({
    url: "/sub/getFloorList.do",
    method: "POST",
    data: {
      BD_DONG_ID: dongId,
      SALE_YN: saleYn
    }
  }).then(res => {
    currFloorList.value = res.data.floorList.map((floor) => {
      floor.saleYn = saleYn;
      return floor;
    });
    // 선택된 층이 있을경우
    if (info.value.BD_FLOOR) {
      floorChange();
    }
  }).catch(err => {
    console.error(err);
  });
}

function floorChange() {
  const selFloor = currFloorList.value.find((floor) => {
    return info.value.BD_FLOOR == floor.FLOOR_NM;
  });
  getRoomList(selFloor.FLOOR_ID, selFloor.saleYn);
}

function getRoomList(floorId, saleYn) {
  axios({
    url: "/sub/getRoomList.do",
    method: "POST",
    data: {
      FLOOR_ID: floorId,
      SALE_YN: saleYn
    }
  }).then(res => {
    currRoomList.value = res.data.roomList.map((room) => {
      room.saleYn = saleYn;
      return room;
    });
    //선택된 호가 있을경우
    if (info.value.BD_HO) {
      roomChange();
    }
  }).catch(err => {
    console.error(err);
  });
}

function roomChange() {
  const selRoom = currRoomList.value.find((room) => {
    return info.value.BD_HO == room.ROOM_NM;
  });
  getRoomInfo(selRoom.ROOM_ID, selRoom.saleYn);
}

function getRoomInfo(roomId, saleYn) {
  $store.commit('toggleShowStatus', 'loading');
  axios({
    url: "/sub/getRoomInfo.do",
    method: "POST",
    data: {
      ROOM_ID: roomId,
      SALE_YN: saleYn
    }
  }).then(res => {
    $store.commit('toggleShowStatus', 'loading');
    const detailInfo = res.data.detailInfo;
    let appArea = 0; // 공급면적
    let exeArea = 0; // 전용면적
    // let privatePer =0; // 전용율
    if (saleYn == "Y") {
      appArea = (detailInfo.SUPPLY_AREA ? detailInfo.SUPPLY_AREA : 0);
      exeArea = (detailInfo.EXCLUSIVE_AREA ? detailInfo.EXCLUSIVE_AREA : 0);
      // privatePer = (detailInfo.EXCLUSIVE_RATE ? detailInfo.EXCLUSIVE_RATE : (exeArea.toFixed(1) / appArea.toFixed(1)) * 100);
    } else {
      appArea = (detailInfo.SUPPLY_AREA ? detailInfo.SUPPLY_AREA : 0);
      exeArea = (detailInfo.EXCLUSIVE_AREA ? detailInfo.EXCLUSIVE_AREA : 0);
      // privatePer = (exeArea.toFixed(1) / appArea.toFixed(1)) * 100;
    }
    info.value.BD_APP_AREA = appArea.toFixed(1);
    info.value.BD_EXE_AREA = exeArea.toFixed(1);
    // $("#privatePer").text(privatePer.toFixed(1) + "%" );
  }).catch(err => {
    console.error(err);
  });
}

function fnSubmit() {
  if (info.value.BD_BUILD_CD == null || info.value.BD_BUILD_CD == "") {
    alert("거래형태를 선택해주세요.");
    return;
  }
  if (info.value.BUILD_TYPE == null || info.value.BUILD_TYPE == "") {
    alert("중개대상물의 종류를 선택해주세요.");
    return;
  }
  if (isFactory.value) {
    if (info.value.BD_NM == null || info.value.BD_NM == "") {
      alert("매물명(건물명)을 입력해주세요.");
      return;
    }
    if (info.value.BD_AREA_CD == null || info.value.BD_AREA_CD == "") {
      alert("지역코드(시도)를 선택해주세요.");
      return;
    }
    if (info.value.BD_SIDO_CD == null || info.value.BD_SIDO_CD == "") {
      alert("지역코드(구군)를 선택해주세요.");
      return;
    }
    if (info.value.BD_ADDR == null || info.value.BD_ADDR == "") {
      alert("주소를 입력해주세요.");
      return;
    }
    if (info.value.LOAD_LAT == null || info.value.LOAD_LAT == "") {
      alert("위도를 입력해주세요.");
      return;
    }
    if (info.value.LOAD_LNG == null || info.value.LOAD_LNG == "") {
      alert("경도를 입력해주세요.");
      return;
    }
    if (!(info.value.LOAD_LAT >= 33 && info.value.LOAD_LAT <= 39) || !(info.value.LOAD_LNG >= 125 && info.value.LOAD_LNG <= 132)) {
      alert("좌표가 올바르지 않습니다.");
      return;
    }
  } else {
    if (info.value.BD_ID == null || info.value.BD_ID == "" || info.value.BD_ID == 999999999) {
      alert("건물을 선택해주세요.");
      return;
    }
  }

  if (info.value.BD_BUILD_CD == "01" && (info.value.BD_PRICE == null || info.value.BD_PRICE == "")) {
    alert("분양가를 입력해주세요.");
    return;
  }
  if ((info.value.BD_BUILD_CD == "02" || info.value.BD_BUILD_CD == "04") && (info.value.BD_PRICE == null || info.value.BD_PRICE == "")) {
    alert("매매가를 입력해주세요.");
    return;
  }
  if (info.value.BD_BUILD_CD == "03") {
    if (info.value.BD_DEPOSIT == null || info.value.BD_DEPOSIT == "") {
      alert("보증금을 입력해주세요.");
      return;
    }
    if (info.value.BD_MON_RENT == null || info.value.BD_MON_RENT == "") {
      alert("월임대료를 입력해주세요.");
      return;
    }
  }

  if (confirm('등록하시겠습니까?')) {
    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');

    if (info.value.BD_BUILD_CD == '01' || info.value.BD_BUILD_CD == '02' || info.value.BD_BUILD_CD == '04') {
      info.value.BD_PRICE = info.value.BD_PRICE * 10000;
    } else if (info.value.BD_BUILD_CD == '03') {
      info.value.BD_DEPOSIT = info.value.BD_DEPOSIT * 10000;
      info.value.BD_MON_RENT = info.value.BD_MON_RENT * 10000;
    }

    //특정대상물일시 처리 추가
    if (isFactory.value) {
      info.value.CENT_ID = 15;
      info.value.BD_ID = 999999999;
    }

    let submitUrl = '';
    if (info.value.BY_ID) {
      submitUrl = "/sub/leaseTradingUpdate.do";
    } else {
      submitUrl = "/sub/leaseTradingInsert.do";
    }

    //formData 생성
    let formData = new FormData();
    Object.keys(info.value).forEach(key => formData.append(key, info.value[key]));

    //파일을 처리하기 위한 formData
    let formDataForFile = new FormData(dataForm.value);
    // formData.append('fileId2', formDataForFile.get('fileId2'));
    // formData.append('fileId3', formDataForFile.get('fileId3'));
    // formData.append('fileId4', formDataForFile.get('fileId4'));
    // formData.append('fileId5', formDataForFile.get('fileId5'));
    
    //파일등록 및 삭제를 처리하기 위한 formData
    // Object.keys(formDataForFile).forEach(key => formData.append(key, info.value[key]));
    for (let i = 1; i <= 30; i++) {
      formData.append(`fileId`, formDataForFile.get(`fileId${i}`));
      if (info.value[`FILE_ID${i}`]) {
        if (info.value[`FILE_DEL_${i}`]) {
          formData.append(`FILE_ID${i}_DEL`, "Y");
        } else if(formDataForFile.get(`fileId${i}`).size > 0) {
          formData.append(`FILE_ID${i}_DEL`, "Y");
        }
      }
    }

    axios({
      url: submitUrl,
      method: "POST",
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }).then(res => {
      console.log(res);
      alert("매물등록이 정상적으로 처리되었습니다.");
      $store.commit('toggleShowStatus', 'dimm');
      $store.commit('toggleShowStatus', 'loading');
      $router.push("/managementList");
    }).catch(err => {
      console.error(err);
      $store.commit('toggleShowStatus', 'dimm');
      $store.commit('toggleShowStatus', 'loading');
    });
  }
}

//file 업로드시 확장자 체크
function onFileChange(e) {
  let fileElements = e.target;
  let file = fileElements.files || e.dataTransfer.files;
  if (!/\.(jpg|png|gif|bmp)$/i.test(file[0].name)) {
    alert('이미지 파일만 선택해 주세요.\n\n현재 파일 : ' + file[0].name);
    //파일 비우기.
    fileElements.value = "";
    //파일명 비우기
    info.value[e.target.id] = "";
  } else {
    //파일명 채우기
    info.value[e.target.id] = file[0].name;
  }
}

function goList() {
  if (confirm("저장하지 않고 목록으로 가시겠습니까?")) {
    $router.push('/managementList');
  }
}

function addressSearch() {
  if (window.kakao?.maps) {
    let geocoder = new window.kakao.maps.services.Geocoder();
    // 주소로 좌표를 검색합니다
    geocoder.addressSearch(info.value.BD_ADDR, function (result, status) {
      // 정상적으로 검색이 완료됐으면 
      if (status === window.kakao.maps.services.Status.OK) {
        info.value.LOAD_LAT = result[0].y;
        info.value.LOAD_LNG = result[0].x;
      } else {
        alert("좌표값을 찾을 수 없습니다.");
      }
    });
  } else {
    loadScript();
  }
}

function loadScript() {
  const script = document.createElement("script");
  const apiKey = "c3128f12b045dcdc0e2dba164c91ec23";
  script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&libraries=clusterer,services&autoload=false`;
  document.head.appendChild(script);
  script.onload = () => {
    window.kakao.maps.load(addressSearch);
  }
}

watch(
  () => isFactory.value,
  (isFactory) => {
    if (isFactory) { //공장타입으로 변환
      info.value.BD_DONG_TYPE = "1";
    }
  }
);

onMounted(() => {
  //$store.dispatch('dispatchLogin');
  setTimeout(() => {
    if (!isLogin.value) {
      $router.push('/');
    } else {
      $store.dispatch('dispatchCommonCodeList');
      $store.dispatch('dispatchSidoList');
      if ($route.query.BY_ID) {
        axios({
          url: "/sub/selectCentBuildByDetail.do",
          method: "POST",
          data: { 'BY_ID': $route.query.BY_ID }
        }).then(res => {
          info.value = res.data.info;

          // 기존 금액 만원단위로 설정
          if (info.value.BD_BUILD_CD == '01' || info.value.BD_BUILD_CD == '02' || info.value.BD_BUILD_CD == '04') {
            info.value.BD_PRICE = info.value.BD_PRICE / 10000;
          } else if (info.value.BD_BUILD_CD == '03') {
            info.value.BD_DEPOSIT = info.value.BD_DEPOSIT / 10000;
            info.value.BD_MON_RENT = info.value.BD_MON_RENT / 10000;
          }
          if(info.value.BD_ID == 999999999) {
            isFactory.value = true;
          }

          getDongList(info.value.BD_ID); //기존 선택한 층이 있을경우 추가작업.
        }).catch(err => {
          console.error(err);
        });
      }
    }
  }, 200);
});

</script>