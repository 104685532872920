<template>
  <div class="data_content">
    <KakaoMap :sido="sido" :buildings="buildings" @build-detail="buildDetail" ref="kakaoMapRef" />

    <BuildDetail v-show="isShowDetail" :building-info="buildingInfo" :subway-list="subwayList" :sales-list="salesList"
      :bd-build-cd="props.searchParam.BD_BUILD_CD" @go-detail="goDetail" @close-layer="isShowDetail = fales"
      ref="childRef" />
  </div>
</template>

<script setup>
import KakaoMap from '@/components/KakaoMap.vue';
import BuildDetail from '@/components/BuildDetail.vue';
import { onMounted, onUnmounted, computed, defineExpose, defineProps, ref, defineEmits } from 'vue'
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import axios from 'axios'
//import common from '@/assets/js/ui.common.js'

const emit = defineEmits(['setSelectCount']);

const buildings = ref();
const sido = ref();
const buildingInfo = ref();
const subwayList = ref();
const salesList = ref();

const buildingList = ref();
const parcelList = ref();
const tradingList = ref();
const leaseList = ref();

const $store = useStore();
const $route = useRoute();
const $router = useRouter();
const isShowFooter = computed(() => {
  return $store.getters.getShowStatus('footer');
});
const isShowDetail = ref(false);
const childRef = ref(null);
const kakaoMapRef = ref(null);

const props = defineProps({
  searchParam: Object
});

function fnSearch() {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'loading');

  //검색 처리 및 맵에 데이터 넣기..
  axios({
    url: "/sub/totalSalesList.do",
    method: "POST",
    data: props.searchParam
  }).then(res => {
    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');

    sido.value = res.data.list.filter((item, i) => {
      return (
        res.data.list.findIndex((item2,) => {
          return item.BD_ID === item2.BD_ID;
        }) === i
      );
    }).map((o) => {
      if (props.searchParam.BD_BUILD_CD == "01") {
        o.PRD_CNT = o.CNT01;
      } else if (props.searchParam.BD_BUILD_CD == "02") {
        o.PRD_CNT = o.CNT02;
      } else if (props.searchParam.BD_BUILD_CD == "03") {
        o.PRD_CNT = o.CNT03;
      } else {
        o.PRD_CNT = 1;
        o.overlay1NotImport = true; //커스텀 오버레이 안쓰기위해
      }
      return o;
    });

    if (props.searchParam.BD_BUILD_CD == '') {
      buildingList.value = res.data.list;
    } else if (props.searchParam.BD_BUILD_CD == '01') {
      parcelList.value = res.data.list;
    } else if (props.searchParam.BD_BUILD_CD == '02') {
      tradingList.value = res.data.list;
    } else if (props.searchParam.BD_BUILD_CD == '03') {
      leaseList.value = res.data.list;
    }

    emit('setSelectCount', res.data.list.length);

  }).catch(err => {
    console.error(err);
  });
}

function buildDetail(building) {
  if (building.BD_ID == 999999999) {
    goDetail(building);
    return;
  }

  isShowDetail.value = false;
  buildingInfo.value = null;
  subwayList.value = null;
  salesList.value = null;

  const mapParam = {
    BD_BUILD_CD: props.searchParam.BD_BUILD_CD,
    BD_AREA_CD: props.searchParam.BD_AREA_CD,
    BD_EXE_AREA: props.searchParam.BD_EXE_AREA,
    BD_ID: building.BD_ID,
    BY_ID: building.BY_ID
  };

  axios({
    url: "/sub/maps/lists.do",
    method: "POST",
    data: mapParam
  }).then(res => {
    isShowDetail.value = true;
    buildingInfo.value = res.data.info;
    subwayList.value = res.data.subList;
    salesList.value = res.data.infolist;
  }).catch(err => {
    console.error(err);
  });

  childRef.value.changeBuildingType(props.searchParam.BD_BUILD_CD == '');
}

function goDetail(info) {
  if (props.searchParam.BD_BUILD_CD == '') {
    //건물
    kakaoMapRef.value.setPosition(info.JIBUN_LAT, info.JIBUN_LNG);
    buildDetail(info);
  } else {
    let path = '';
    switch (info.BD_BUILD_CD) {
      case "01": path = '/parcel/parcelDetail/' + info.BY_ID; break;
      case "02": path = '/trading/tradingDetail/' + info.BY_ID; break;
      case "03": path = '/lease/leaseDetail/' + info.BY_ID; break;
    }
    if (path != '') {
      $router.push(path);
    }
  }
}

defineExpose({ fnSearch })

onMounted(() => {
  if (isShowFooter.value) {
    $store.commit('toggleShowStatus', 'footer');
  }
  if (!$route.query.SEARCH_NM) {
    fnSearch();
  }

  //fnSearch();
});
onUnmounted(() => {
  if (!isShowFooter.value) {
    $store.commit('toggleShowStatus', 'footer');
  }
});

</script>