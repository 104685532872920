<template>
  <div id="detailInfo" class="popup detail_map_layer" v-if="props.buildingInfo" :style="pt0 ? 'padding-top: 0px;' : ''">
    <div class="mlayer_wrap">
      <div class="top_cont">
        <a href="javascript:;" class="popup_close" @click="emit('closeLayer')"><i class="xi-close" id=""></i></a>
        <h3 class="ellipsis">{{ props.buildingInfo.BD_NM }}</h3>
        <span>
          {{ props.buildingInfo.BD_AUTH_YN == "Y" ? props.buildingInfo.BD_JIBUN_ADDR : props.buildingInfo.BD_ADDR }}
        </span>
      </div>

      <!-- tab 버튼-->
      <ul class="menu_tab" v-show="showBuildingInfoTab">
        <li><a href="javascript:;" @click="changeShowTab(1)" :class="{ 'on': showTab01 }">건물정보</a></li>
        <li><a href="javascript:;" @click="changeShowTab(2)" :class="{ 'on': showTab02 }">{{ bdBuildCdNm }}정보</a></li>
      </ul>
      <!-- ///tab 버튼 -->

      <!-- 건물정보 탭 -->
      <div class="bulid_info_wrap info_txt_box" :class="{ 'on': showTab01 }">
        <ul>
          <li class="first_li">
            <div class="info_txt">
              <div class="btn_area on_btn">
                <!-- 클래스 on추가 시 버튼 활성화 -->
                <a href="javascript:;" @click="areaShowType = 'sqm'" class="btn"
                  :class="{ 'on': areaShowType == 'sqm' }">㎡</a><!-- //활성화 class: on 추가 -->
                <a href="javascript:;" @click="areaShowType = 'flat'" class="btn"
                  :class="{ 'on': areaShowType == 'flat' }">py</a>
              </div>
              <dl>
                <dt>대지면적</dt>
                <dd v-if="areaShowType == 'sqm'">{{ common.comma(Number(props.buildingInfo.BD_SITE_AREA).toFixed(0)) }}
                  ㎡
                </dd>
                <dd v-else-if="areaShowType == 'flat'">{{ common.comma((Number(props.buildingInfo.BD_SITE_AREA) /
                  3.306).toFixed(0)) }} py</dd>
                <dt>연면적</dt>
                <dd v-if="areaShowType == 'sqm'">{{ common.comma(Number(props.buildingInfo.BD_YEA_AREA).toFixed(0)) }} ㎡
                </dd>
                <dd v-else-if="areaShowType == 'flat'">{{ common.comma((Number(props.buildingInfo.BD_YEA_AREA) /
                  3.306).toFixed(0)) }} py</dd>
              </dl>
            </div>
          </li>
          <li>
            <h4 @click="$event.target.offsetParent.classList.toggle('off')">건물 정보</h4>
            <div class="info_txt">
              <dl class="on">
                <dt>지상/지하</dt>
                <dd id="buildDongInfo"></dd>

                <dt>사용승인</dt>
                <dd v-if="props.buildingInfo.BD_AUTH_YN == 'N'">
                  {{ props.buildingInfo.BD_AUTH_DT.replace(/(\d{4})(\d{2})(\d{2})/, '$1년 $2월 $3일') }}
                </dd>
                <dd v-else>현재 분양중인 건물입니다.</dd>

                <dt>승강기</dt>
                <dd v-if="props.buildingInfo.BD_PASS_EV != null && props.buildingInfo.BD_EMRG_EV != null">
                  승용 {{ props.buildingInfo.BD_PASS_EV }} / 비상 {{ props.buildingInfo.BD_EMRG_EV }}
                </dd>
                <dd v-else></dd>

                <dt>주차대수</dt>
                <dd
                  v-if="!isNaN(parseInt(props.buildingInfo.BD_INNER_CAR) + parseInt(props.buildingInfo.BD_OUTER_CAR))">
                  {{ parseInt(props.buildingInfo.BD_INNER_CAR) + parseInt(props.buildingInfo.BD_OUTER_CAR) }} 대
                </dd>
                <dd v-else></dd>

                <template v-if="props.subwayList.length > 0">
                  <dt>지하철</dt>
                  <dd>
                    <span class="br_txt" v-for="subway in props.subwayList" :key="subway">
                      {{ subway.SUB_STATION + ' ' + subway.SUB_TIME }} 분
                    </span>
                  </dd>
                </template>

                <dt id="buildMngTelArea">관리사무실</dt>
                <dd id="buildMngTel"></dd>
              </dl>
            </div>
          </li>
          <li>
            <h4 @click="$event.target.offsetParent.classList.toggle('off')">상세 정보</h4>
            <div class="info_txt">
              <div class="insert_area">
                <div class="select_box">
                  <div class="s_txt">
                    <select class="select_list" v-model="BD_DONG" @change="dongChange">
                      <option value="">동</option>
                      <option v-for="dong in currDongList" :key="dong" :value="dong.dongName">{{ dong.dongName }}
                      </option>
                    </select>
                    <span class="arrow"><i class="xi-angle-down"></i></span>
                  </div>
                </div>
                <div class="select_box">
                  <div class="s_txt">
                    <select class="select_list" v-model="BD_FLOOR" @change="floorChange">
                      <option value="">층</option>
                      <option v-for="floor in currFloorList" :key="floor" :value="floor.FLOOR_NM">{{
                        floor.FLOOR_NM
                        }}
                      </option>
                    </select>
                    <span class="arrow"><i class="xi-angle-down"></i></span>
                  </div>
                </div>
                <div class="select_box">
                  <div class="s_txt">
                    <select class="select_list" v-model="BD_HO" @change="roomChange">
                      <option value="">호</option>
                      <option v-for="room in currRoomList" :key="room" :value="room.ROOM_NM">{{ room.ROOM_NM
                        }}
                      </option>
                    </select>
                    <span class="arrow"><i class="xi-angle-down"></i></span>
                  </div>
                </div>
              </div>
              <dl>
                <dt>공급면적</dt>
                <dd v-if="areaShowType == 'sqm'">{{ appArea }} ㎡</dd>
                <dd v-else-if="areaShowType == 'flat'">{{ (appArea / 3.306).toFixed(0) }} py</dd>
                <dt>전용면적</dt>
                <dd v-if="areaShowType == 'sqm'">{{ exeArea }} ㎡</dd>
                <dd v-else-if="areaShowType == 'flat'">{{ (exeArea / 3.306).toFixed(0) }} py</dd>
                <dt>전용률</dt>
                <dd>{{ privatePer }} %</dd>
              </dl>
            </div>
          </li>
        </ul>
      </div>
      <!-- ///건물정보 탭 -->

      <!-- 매매/임대/분양정보 탭 -->
      <div class="bulid_info_wrap info_list_box" :class="{ 'on': showTab02 }">
        <ul>
          <template v-for="sales in props.salesList" :key="sales">
            <li v-if="sales.BD_BUILD_CD == props.bdBuildCd">
              <a href="javascript:;" @click="emit('goDetail', sales)">
                <img :src="'/image/' + sales.FILE_ID1 + '/view.do'" @error="common.replaceDefaultImage" />
                <div class="price_box">
                  <span class="ellipsis" v-if="areaShowType == 'sqm'">계약 {{ parseFloat(sales.BD_APP_AREA).toFixed(1) }}
                    ㎡
                    / 전용 {{ parseFloat(sales.BD_EXE_AREA).toFixed(1) }} ㎡</span>
                  <span class="ellipsis" v-else-if="areaShowType == 'flat'">계약 {{ (parseFloat(sales.BD_APP_AREA) /
                    3.306).toFixed(1) }} py / 전용 {{ (parseFloat(sales.BD_EXE_AREA) / 3.306).toFixed(1) }} py</span>
                  <span class="lineClampv"> {{ (typeof sales.BD_FLOOR == "undefined" ? "" :
                    sales.BD_FLOOR.replace('층', '') + " 층 &nbsp;") }}
                    입주가능일 : {{ sales.MOVE_INTO_DT }}
                  </span>
                  <span class="sale_price" v-if="sales.BD_BUILD_CD == '01'"> {{ common.comma(sales.BD_PRICE / 10000) }}
                    만원</span>
                  <span class="sale_price" v-else-if="sales.BD_BUILD_CD == '02'"> {{ common.comma(sales.BD_PRICE /
                    10000) }} 만원</span>
                  <span class="sale_price" v-else-if="sales.BD_BUILD_CD == '03'"> {{ common.comma(sales.BD_DEPOSIT /
                    10000) }} 만원 / {{
                      common.comma(sales.BD_MON_RENT / 10000) }} 만원</span>
                </div>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { defineProps, defineEmits, ref, computed, defineExpose, watch } from 'vue'
import { useRoute } from 'vue-router'
import common from '@/assets/js/ui.common.js'
import axios from 'axios'

const $route = useRoute();
const emit = defineEmits(['goDeatil', 'closeLayer']);
const props = defineProps({
  bdBuildCd: String,
  buildingInfo: Object,
  subwayList: Array,
  salesList: Array
});

const BD_DONG = ref('');
const BD_FLOOR = ref('');
const BD_HO = ref('');
const currDongList = ref([]);
const currFloorList = ref([]);
const currRoomList = ref([]);
const appArea = ref(); // 공급면적
const exeArea = ref(); // 전용면적
const privatePer = ref(); // 전용율
const showTab01 = ref(false);
const showTab02 = ref(true);
const showBuildingInfoTab = ref(true);
const showTab = ref(true);
const bdBuildCdNm = computed(() => {
  let rtn = "";
  switch (props.bdBuildCd) {
    case "02": rtn = "매매"; break;
    case "03": rtn = "임대"; break;
    case "01": rtn = "분양"; break;
  }
  return rtn;
});
const pt0 = computed(() => {
  return $route.path == '/total/totalMap';
});

const areaShowType = ref('sqm');

function changeShowTab(tabIdx) {
  if (tabIdx == 1) {
    showTab01.value = true;
    showTab02.value = false;
  }
  if (tabIdx == 2) {
    showTab01.value = false;
    showTab02.value = true;
  }
}

function changeBuildingType(isBuilding) {
  if (isBuilding) {
    //건물타입
    showBuildingInfoTab.value = false;
    changeShowTab(1);
  } else {
    //아닌경우 원복
    showBuildingInfoTab.value = true;
    changeShowTab(2);
  }
}

function hideTab() {
  showTab.value = false;
}

defineExpose({ changeBuildingType, hideTab })

watch(
  () => props.buildingInfo,
  (buildingInfo) => {
    if (buildingInfo == null) {
      return;
    }
    BD_DONG.value = '';
    BD_FLOOR.value = '';
    BD_HO.value = '';
    appArea.value = '';
    exeArea.value = '';
    privatePer.value = '';
    getDongList(buildingInfo.BD_ID);
  }
);

function getDongList(bdId) {
  if (!bdId) {
    return;
  }
  axios({
    url: "/sub/getDongList.do",
    method: "POST",
    data: { 'BD_ID': bdId }
  }).then(res => {
    const dongInfo = res.data.dongInfo;
    const dongName = res.data.dongInfo.BD_NM;
    const dongList = res.data.dongList;
    if (dongList.length > 0) {
      //임대,매매인경우
      currDongList.value = dongList.map((dong) => {
        if (dong.DONG_NM && dong.DONG_NM.trim() != "") {
          dong.dongName = dong.DONG_NM;
        } else {
          dong.dongName = dongName;
        }
        dong.dongId = dong.BD_DONG_ID;
        dong.saleYn = "N";
        return dong;
      });
    } else {
      // 분양인 경우
      currDongList.value = [{
        dongName: dongName,
        dongId: dongInfo.SALE_ID,
        saleYn: "Y"
      }];
    }
  }).catch(err => {
    console.error(err);
  });
}

function dongChange() {
  const selDong = currDongList.value.find((dong) => {
    return BD_DONG.value == dong.dongName;
  });
  getFloorList(selDong.dongId, selDong.saleYn);
}


function getFloorList(dongId, saleYn) {
  axios({
    url: "/sub/getFloorList.do",
    method: "POST",
    data: {
      BD_DONG_ID: dongId,
      SALE_YN: saleYn
    }
  }).then(res => {
    currFloorList.value = res.data.floorList.map((floor) => {
      floor.saleYn = saleYn;
      return floor;
    });
  }).catch(err => {
    console.error(err);
  });
}

function floorChange() {
  const selFloor = currFloorList.value.find((floor) => {
    return BD_FLOOR.value == floor.FLOOR_NM;
  });
  getRoomList(selFloor.FLOOR_ID, selFloor.saleYn);
}

function getRoomList(floorId, saleYn) {
  axios({
    url: "/sub/getRoomList.do",
    method: "POST",
    data: {
      FLOOR_ID: floorId,
      SALE_YN: saleYn
    }
  }).then(res => {
    currRoomList.value = res.data.roomList.map((room) => {
      room.saleYn = saleYn;
      return room;
    });
  }).catch(err => {
    console.error(err);
  });
}

function roomChange() {
  const selRoom = currRoomList.value.find((room) => {
    return BD_HO.value == room.ROOM_NM;
  });
  getRoomInfo(selRoom.ROOM_ID, selRoom.saleYn);
}

function getRoomInfo(roomId, saleYn) {
  // $store.commit('toggleShowStatus', 'loading');
  axios({
    url: "/sub/getRoomInfo.do",
    method: "POST",
    data: {
      ROOM_ID: roomId,
      SALE_YN: saleYn
    }
  }).then(res => {
    // $store.commit('toggleShowStatus', 'loading');
    const detailInfo = res.data.detailInfo;
    if (saleYn == "Y") {
      appArea.value = (detailInfo.SUPPLY_AREA ? detailInfo.SUPPLY_AREA : 0);
      exeArea.value = (detailInfo.EXCLUSIVE_AREA ? detailInfo.EXCLUSIVE_AREA : 0);
      privatePer.value = (detailInfo.EXCLUSIVE_RATE ? detailInfo.EXCLUSIVE_RATE : (exeArea.value.toFixed(1) / appArea.value.toFixed(1)) * 100).toFixed(2);
    } else {
      appArea.value = (detailInfo.SUPPLY_AREA ? detailInfo.SUPPLY_AREA : 0);
      exeArea.value = (detailInfo.EXCLUSIVE_AREA ? detailInfo.EXCLUSIVE_AREA : 0);
      privatePer.value = ((exeArea.value.toFixed(1) / appArea.value.toFixed(1)) * 100).toFixed(2);
    }
    if (privatePer.value == "Infinity") {
      privatePer.value = 0;
    }
  }).catch(err => {
    console.error(err);
  });
}

</script>