<template>
  <div class="wrap">
    
    <div class="container">
      <!-- dimm / 팝업창 -->
      <div class="dimm" v-show="isShowDimm"></div>
      <!-- 로딩바 -->
      <div class="lds-ring" v-show="isShowLoading">
        <div></div>
      </div>
      <div class="popup" id="">
        <!-- 팝업 컨텐츠 -->
        <div class="popup_box">
        </div>
      </div>
      <!-- header -->
      <header class="header" v-show="isShowHeader">
        <div class="h_content">
          <div class="h_left">
            <a href="javascript:;" id="nav" @click="navOpen"><i class="xi-bars"></i></a>
          </div>
          <h1 class="logo">
            <router-link to="/"><img src="@/assets/images/logo.png" alt="로고"></router-link>
          </h1>
          <div class="h_right">
            <a href="javascript:;"><em>031-737-2003</em></a>
          </div>
        </div>
      </header>
      <!-- //header -->
      <!-- 메뉴 -->
      <aside id="gnb" v-show="isShowGnb">
        <nav>
          <a href="javascript:;"><i class="xi-close" id="nav_close" @click="navClose"></i></a>
          <ul>
            <li><router-link to="/trading/tradingMap" class="menu01" @click="navClose">매매검색</router-link></li>
            <li><router-link to="/lease/leaseMap" class="menu02" @click="navClose">임대검색</router-link></li>
            <li><router-link to="/parcel/parcelMap" class="menu03" @click="navClose">신규분양</router-link></li>
            <!-- <li><a href="https://www.kic114.kr/transactionMap.do" target="_blank" class="menu04">실거래가</a></li> -->
            <li><router-link to="/notice/noticeList" class="menu05" @click="navClose">공지사항</router-link></li>
            <li><router-link to="/about" class="menu06" @click="navClose">공장리더스 소개</router-link></li>
            <li><router-link to="/location" class="menu07" @click="navClose">오시는길</router-link></li>
            <li v-if="isLogin"><router-link to="/managementWrite" class="menu08" @click="navClose">매물등록</router-link></li>
            <li v-if="isLogin"><router-link to="/managementList" class="menu09" @click="navClose">매물관리</router-link></li>
            <li v-if="isLogin"><a href="javascript:;" class="menu10" @click="logout">로그아웃</a></li>
          </ul>
        </nav>
      </aside> <!-- ///메뉴 -->
      <main>
        <router-view :key="$route.fullPath"/>
      </main>
    </div>
    <!-- //container -->
    <footer v-show="isShowFooter">
      <div class="f_wrap">
        <ul>
          <li><a href="javascript:;" @click="openFileInNewTab('/policy')">이용약관</a></li>
          <li><a href="javascript:;" @click="openFileInNewTab('/private')">개인정보처리방침</a></li>
          <li><a href="javascript:;">(주)공장리더스부동산중개법인 41133-2022-00004 양유선</a></li>
          <li><a href="javascript:;">개인정보관리책임자 : 양유선 / 고객센터 031-737-2003</a></li>
          <li><a href="javascript:;">경기 성남시 중원구 둔촌대로457번길 27 105호</a></li>
          <li><a href="javascript:;">사업자등록번호 : 235-87-02492</a></li>
          <li><a href="javascript:;">(주)공장리더스부동산중개법인 basragy@naver.com</a></li>
          <li><a href="javascript:;">FAX : 031-737-2424</a></li>
        </ul>
      </div>
    </footer>
  </div>
  
</template>

<style>
@import "@/assets/css/swiper.min.css";
@import "@/assets/css/ui.common.css";
@import "@/assets/css/ui.reset";
@import "@/assets/css/XEIcon-2.2.0/XEIcon-2.2.0/xeicon.min.css";
</style>

<script setup>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import { onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import axios from 'axios'

const $router = useRouter();
const $store = useStore();
const isShowDimm = computed(() => {
  return $store.getters.getShowStatus('dimm');
});
const isShowGnb = computed(() => {
  return $store.getters.getShowStatus('gnb');
});
const isShowFooter = computed(() => {
  return $store.getters.getShowStatus('footer');
});
const isShowHeader = computed(() => {
  return $store.getters.getShowStatus('header');
});
const isShowLoading = computed(() => {
  return $store.getters.getShowStatus('loading');
});
const isLogin = computed(() => {
  return $store.getters.getIsLogin();
});

function navOpen() {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'gnb');
}

function navClose() {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'gnb');
}

function logout() {
  axios({
    url: "/sub/logout.do",
    method: "POST"
    // eslint-disable-next-line
  }).then(res => {
    $store.dispatch('dispatchLogin');
    $router.push("/");
    navClose();
  }).catch(err => {
    console.error(err);
  });
}

onMounted(() => {
  $store.dispatch('dispatchLogin');
});


//240513 추가
function openFileInNewTab(filePath){
  window.open(filePath, '_blank');
}

</script>