<template>
  <div class="content intro">
    <!-- 타이틀 / 버튼 상단 영역 -->
    <div class="top_area">
      <div class="intro_bg">
        <h2>공장리더스 소개</h2>
      </div>
    </div>
    <!-- 데이터 박스 -->
    <div class="data_box">
      <div class="data_content" style="position: relative;">
        <div class="intro_wrap">
          <div class="profile"></div>
          <div class="txt">
            <p class="title">
              긴 인생을 살아가는 데 있어<br>
              가장 중요한 생존력은 정직함이다.
            </p>
            <p>
              첫 중개업을 지식산업센터 분야로 시작!!<br>
              제 사고의 중심 문구로 삼으며 근 15년 업을 해 왔습니다.<br>
              <br>
              <em>정직, 솔직, 약속 이행</em> 세가지는 일과 삶에서 저의 중요 요소입니다.<br>
              책임감으로 부끄러움 없이 고객을 대하고 즐겁게 일할때 행복을 느끼는 사람으로 (주)공장리더스부동산중개법인 사업장을 운영하고 있습니다.<br><br>
              부동산, 사람들, 숱한 인연 속에 좋은 인연으로 저와 제 사업장이 고객과 고객 사업장,재산권에 도움과 보탬이 되는 협력자로서의 동행이 되길 바라는 마음으로 소개글을 올립니다.<br><br>
              감사합니다.
            </p>
          </div>

        </div>
      </div>
    </div>
    <!-- ///데이터 박스 -->
  </div>
</template>