<template>
    <div class="content intro location">
        <!-- 타이틀 / 버튼 상단 영역 -->
        <div class="top_area">
            <div class="intro_bg">
                <h2>공장리더스 오시는길</h2>
            </div>
        </div>
        <!-- 데이터 박스 -->
            <div class="data_box">
                <div class="data_content" style="position: relative;">
                    <div class="intro_wrap location">
                        <h3>LOCATION</h3>
                        <div class="map"><img src="@/assets/images/img-map.png" alt="공장리더스 위치" @click="openMap"></div>
                        <div class="txt_wrap">
                            <h4>(주)공장리더스부동산중개법인</h4>
                            <span>경기도 성남시 중원구 둔촌대로457번길 27 상대원동, 성남우림라이온스밸리 1차</span>
                            <span><i class="xi-call"></i>031-737-2003<i class="xi-mobile" style="padding-left: 20px"></i>010-9266-1446</span>
                            <span><i class="xi-calendar"></i>매일 10:00 - 20:00</span>
                        </div>
                    </div>
                </div>
            </div>
        <!-- ///데이터 박스 -->
    </div>
</template>

<script setup>
function openMap() {
    window.open('https://map.kakao.com/?urlX=537748&urlY=1092855&itemId=1584273482');
}
</script>