<template>
  <div class="data_content">
    <ul class="itemcard_list">
      <li v-for="data in salesList" :key="data">
        <router-link :to="'/trading/tradingDetail/' + data.BY_ID">
          <dl>
            <dt>
              <img :src="'/image/' + data.FILE_ID1 + '/view.do'" @error="common.replaceDefaultImage">
              <span class="sale_price">{{ common.comma(data.BD_PRICE / 10000) }} 만원</span>
            </dt>
            <dd>
              <h3 class="ellipsis">{{ data.BD_NM }}</h3>
              <span class="lineClamp">
                {{ data.BD_ADDR ? data.BD_ADDR : data.BD_JIBUN_ADDR }}
                {{ data.BD_FLOOR != null ? data.BD_FLOOR.replaceAll("층", "") + " 층" : "" }}
                계약 {{ data.BD_APP_AREA }} ㎡ / 전용 {{ data.BD_EXE_AREA }} ㎡
              </span>
            </dd>
          </dl>
        </router-link>
      </li>
    </ul>
  </div>
  <!-- 페이지네이션 -->
  <PagingList :total-cnt="pageInfo.totalCnt" :total-page="pageInfo.totalPage" @get-page-data="getListData" />
</template>

<script setup>
import common from '@/assets/js/ui.common.js'
import PagingList from '@/components/PagingList.vue';
import { onMounted, ref, defineExpose, defineProps } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'

const $store = useStore();
const props = defineProps({
  searchParam: Object
});

const salesList = ref(null);
const pageInfo = ref({
  totalCnt: Number,
  totalPage: Number
});
let currParam = {};

// function imageFile1(data) {
//   return "/image/" + data.FILE_ID1 + "/view.do";
// }

function fnSearch() {
  currParam = JSON.parse(JSON.stringify(props.searchParam));
  getListData(1);
}

function getListData(pageIdx) {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'loading');

  currParam.PAGE_INDEX = pageIdx;
  axios({
    url: "/sub/seleteSaleList.do",
    method: "POST",
    data: currParam
  }).then(res => {
    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');
    salesList.value = res.data.list;
    pageInfo.value = res.data.paging;
  }).catch(err => {
    console.error(err);
  });
}

defineExpose({ fnSearch })

onMounted(() => {
  fnSearch();
});

</script>