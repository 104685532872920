import axios from "axios";
import { createStore } from "vuex";

// state, getters, mutations, actions, modules
export default createStore({
    state: {
        isShowObject: {
            'dimm': false,
            'gnb': false,
            'footer': true,
            'loading': false,
            'full': false,
            'header': true
        },
        commonCodeList: [],
        sidoList: [],
        isLogin: false
    },
    getters: {
        getCommonCodeList: (state) => (grpCode) => {
            return state.commonCodeList.filter(code => code.codeId == grpCode);
        },
        getSidoList: (state) => (upSidoId) => {
            return state.sidoList.filter(sido => sido.UP_SIDO_ID == upSidoId);
        },
        getShowStatus: (state) => (type) => {
            return state.isShowObject[type];
        },
        getIsLogin: (state) => () => {
            return state.isLogin;
        },
    },
    actions: {
        dispatchCommonCodeList(context) {
            if (context.state.commonCodeList.length == 0) {
                axios({
                    url: "/sub/selectCommonCodeList.do",
                    method: "POST"
                }).then(res => {
                    context.commit('setCommonCodeList', res.data.list);
                }).catch(err => {
                    console.error(err);
                });
            }
        },
        dispatchSidoList(context) {
            if (context.state.sidoList.length == 0) {
                axios({
                    url: "/sub/selectAllSidoList.do",
                    method: "POST"
                }).then(res => {
                    context.commit('setSidoList', res.data.list);
                }).catch(err => {
                    console.error(err);
                });
            }
        },
        dispatchLogin(context) {
            axios({
                url: "/sub/loginCheck.do",
                method: "POST"
            }).then(res => {
                let isLogin = !!res.data.isLogin;
                if (context.state.isLogin != isLogin) {
                    context.commit('toggleLogin');
                }
            }).catch(err => {
                console.error(err);
            });
        }
    },
    mutations: {
        toggleShowStatus(state, payload) {
            state.isShowObject[payload] = !state.isShowObject[payload];
        },
        setShowStatus(state, payload) {
            state.isShowObject[payload.key] = payload.value;
        },
        setCommonCodeList(state, payload) {
            state.commonCodeList = payload;
        },
        setSidoList(state, payload) {
            state.sidoList = payload;
        },
        toggleLogin(state) {
            state.isLogin = !state.isLogin;
        }
    },
});