<template>
  <form id="searchForm" name="searchForm">
    <div class="content">
      <!-- 타이틀 / 버튼 상단 영역 -->
      <div class="sub_top_area">
        <div class="top_wrap">
          <div class="t_content">
            <h3>매물관리</h3>
            <div class="middle_box">
              <h4>검색조건</h4>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_AREA_CD" @change="searchParam.BD_SIDO_CD = ''">
                    <option value="">시도</option>
                    <option v-for="areaCd in areaCdList" :key="areaCd" :value="areaCd.SIDO_ID">{{ areaCd.SIDO_NAME }}
                    </option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_SIDO_CD">
                    <option value="">구군</option>
                    <option v-for="subAreaCd in subAreaCdList" :key="subAreaCd" :value="subAreaCd.SIDO_ID">{{
                      subAreaCd.SIDO_NAME }}</option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_EXE_AREA">
                    <option value="">전용면적</option>
                    <option v-for="exeAreaCd in exeAreaCdList" :key="exeAreaCd" :value="exeAreaCd.dtlCodeId">
                      {{ exeAreaCd.dtlCodeNm }}</option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_PRICE">
                    <option value="">매매가</option>
                    <option v-for="priceCd in priceCdList" :key="priceCd" :value="priceCd.dtlCodeId">
                      {{ priceCd.dtlCodeNm }}</option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="form_box">
                <div class="serach_box">
                  <div class="s_txt">
                    <button type="button" class="btn_search" @click="fnSearch"></button>
                    <input type="text" v-model="searchParam.SEARCH_NM" class="search_txt"
                      placeholder="건물명, 도로명주소, 지번주소, 지하철역" value="" @keydown.enter.prevent="fnSearch">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 모바일용 -->
      <div class="sub_top_area mobile" :class="{ 'on': openFilter }">
        <div class="top_wrap">
          <div class="t_content">
            <button type="button" class="btn filter" :class="{ 'on': openFilter }" @click="openFilter = !openFilter"><i
                class="xi-filter"></i>필터</button>
          </div>
          <div class="middle_box">
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_AREA_CD" @change="searchParam.BD_SIDO_CD = ''">
                  <option value="">시도</option>
                  <option v-for="areaCd in areaCdList" :key="areaCd" :value="areaCd.SIDO_ID">{{ areaCd.SIDO_NAME }}
                  </option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_SIDO_CD">
                  <option value="">구군</option>
                  <option v-for="subAreaCd in subAreaCdList" :key="subAreaCd" :value="subAreaCd.SIDO_ID">{{
                    subAreaCd.SIDO_NAME }}</option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_EXE_AREA">
                  <option value="">전용면적</option>
                  <option v-for="exeAreaCd in exeAreaCdList" :key="exeAreaCd" :value="exeAreaCd.dtlCodeId">
                    {{ exeAreaCd.dtlCodeNm }}</option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_PRICE">
                  <option value="">매매가</option>
                  <option v-for="priceCd in priceCdList" :key="priceCd" :value="priceCd.dtlCodeId">
                    {{ priceCd.dtlCodeNm }}</option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="form_box">
              <div class="serach_box">
                <div class="s_txt">
                  <button type="button" class="btn_search" @click="fnSearch"></button>
                  <input type="text" v-model="searchParam.SEARCH_NM" class="search_txt"
                    placeholder="건물명, 도로명주소, 지번주소, 지하철역" value="" @keydown.enter.prevent="fnSearch">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ///모바일용 -->
      <!-- 데이터 박스 -->
      <div class="data_box">
        <div class="data_content">
          <ul class="itemcard_list">
            <li v-for="info in salesList" :key="info">
              <router-link :to="{ path: 'managementWrite', query: { BY_ID: info.BY_ID } }">
                <dl>
                  <dt>
                    <img :src="'/image/' + info.FILE_ID1 + '/view.do'" @error="common.replaceDefaultImage">
                    <span class="sale_price" v-if="info.BD_BUILD_CD == '01'">{{ common.comma(info.BD_PRICE / 10000)
                      }} 만원</span>
                    <span class="sale_price" v-else-if="info.BD_BUILD_CD == '02' || info.BD_BUILD_CD == '04'">{{ common.comma(info.BD_PRICE / 10000)
                      }} 만원</span>
                    <span class="sale_price" v-else-if="info.BD_BUILD_CD == '03'">{{ common.comma(info.BD_DEPOSIT /
                      10000) }} 만원 / 월
                      {{ common.comma(info.BD_MON_RENT / 10000) }} 만원</span>
                  </dt>
                  <dd>
                    <h3 class="ellipsis">{{ info.BD_NM }}</h3>
                    <span class="lineClamp">
                      {{ info.BD_ADDR ? info.BD_ADDR : info.BD_JIBUN_ADDR }}
                      {{ info.BD_FLOOR != null ? info.BD_FLOOR.replaceAll("층", "") + " 층" : "" }}
                      계약 {{ info.BD_APP_AREA }} ㎡ / 전용 {{ info.BD_EXE_AREA }} ㎡
                    </span>
                  </dd>
                </dl>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- 페이지네이션 -->
        <PagingList :total-cnt="pageInfo.totalCnt" :total-page="pageInfo.totalPage" @get-page-data="getListData" />
      </div>
      <!-- ///데이터 박스 -->
    </div>
  </form>
</template>

<script setup>
import common from '@/assets/js/ui.common.js'
import PagingList from '@/components/PagingList.vue';
import { ref, onMounted, computed } from 'vue'
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from 'axios'

const $router = useRouter();
const $store = useStore();
const areaCdList = computed(() => {
  return $store.getters.getSidoList('ROOT');
});
const subAreaCdList = computed(() => {
  return $store.getters.getSidoList(searchParam.value.BD_AREA_CD);
});
const exeAreaCdList = computed(() => {
  return $store.getters.getCommonCodeList('CM004');
});
const priceCdList = computed(() => {
  return $store.getters.getCommonCodeList('CM006');
});
const isLogin = computed(() => {
  return $store.getters.getIsLogin();
});

const searchParam = ref({
  SEARCH_NM: '',
  BD_AREA_CD: '',
  BD_SIDO_CD: '',
  BD_EXE_AREA: '',
  BD_PRICE: '',
  VIEW_3D_YN: ''
});

const openFilter = ref(false);
const salesList = ref(null);
const pageInfo = ref({
  totalCnt: Number,
  totalPage: Number
});
let currParam = {};

function fnSearch() {
  openFilter.value = false;
  currParam = JSON.parse(JSON.stringify(searchParam.value));
  getListData(1);
}

function getListData(pageIdx) {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'loading');

  currParam.PAGE_INDEX = pageIdx;
  axios({
    url: "/sub/seleteSaleList.do",
    method: "POST",
    data: currParam
  }).then(res => {
    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');
    salesList.value = res.data.list;
    pageInfo.value = res.data.paging;
  }).catch(err => {
    console.error(err);
  });
}

// function goWrite(info) {
//   let path = '';
//   switch (info.BD_BUILD_CD) {
//     case "01": path = '/parcel/parcelDetail/' + info.BY_ID; break;
//     case "02": path = '/trading/tradingDetail/' + info.BY_ID; break;
//     case "03": path = '/lease/leaseDetail/' + info.BY_ID; break;
//   }
//   if (path != '') {
//     $router.push(path);
//   }
// }

onMounted(() => {
  setTimeout(() => {
    if (!isLogin.value) {
      $router.push('/');
    } else {
      $store.dispatch('dispatchCommonCodeList');
      $store.dispatch('dispatchSidoList');
      fnSearch();
    }
  }, 200);
});

</script>
