<template>
  <div class="data_content">
    <ul class="itemcard_list">
      <li v-for="info in currSalesList" :key="info">
        <a href="javascript:;" @click="goDetail(info)">
          <dl>
            <dt>
              <img :src="'/image/' + info.FILE_ID1 + '/view.do'" @error="common.replaceDefaultImage">
              <span class="sale_price" v-if="info.BD_BUILD_CD == '01'">{{ info.BD_PRICE / 10000 }} 만원</span>
              <span class="sale_price" v-else-if="info.BD_BUILD_CD == '02'">{{ info.BD_PRICE / 10000 }} 만원</span>
              <span class="sale_price" v-else-if="info.BD_BUILD_CD == '03'">{{ info.BD_DEPOSIT / 10000 }} 만원 / 월 {{
                info.BD_MON_RENT / 10000 }} 만원</span>
            </dt>
            <dd>
              <h3 class="ellipsis">{{ info.BD_NM }}</h3>
              <span class="lineClamp">
                {{ info.BD_ADDR ? info.BD_ADDR : info.BD_JIBUN_ADDR }}
                {{ info.BD_FLOOR != null ? info.BD_FLOOR.replaceAll("층", "") + " 층" : "" }}
                계약 {{ info.BD_APP_AREA }} ㎡ / 전용 {{ info.BD_EXE_AREA }} ㎡
              </span>
            </dd>
          </dl>
        </a>
      </li>
    </ul>
  </div>
  <!-- 페이지네이션 -->
  <PagingList :total-cnt="pageInfo.totalCnt" :total-page="pageInfo.totalPage" @get-page-data="getListData" />
</template>

<script setup>
import common from '@/assets/js/ui.common.js'
import PagingList from '@/components/PagingList.vue';
import { onMounted, ref, defineExpose, defineProps, computed, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'

const emit = defineEmits(['setSelectCount']);

const $router = useRouter();
const $store = useStore();
const props = defineProps({
  searchParam: Object
});

const salesList = ref(null);
const currSalesList = computed(() => {
  let rtn = [];
  if (salesList.value) {
    rtn = salesList.value.slice(12 * (currPageIdx.value - 1), 12 * currPageIdx.value);
  }
  return rtn;
});
const pageInfo = ref({
  totalCnt: Number,
  totalPage: Number
});
const currPageIdx = ref(1);
let currParam = {};

function fnSearch() {
  currParam = JSON.parse(JSON.stringify(props.searchParam));
  getListAll();
  getListData(1);
}

function getListAll() {
  $store.commit('toggleShowStatus', 'dimm');
  $store.commit('toggleShowStatus', 'loading');

  axios({
    url: "/sub/totalSalesList.do",
    method: "POST",
    data: currParam
  }).then(res => {
    $store.commit('toggleShowStatus', 'dimm');
    $store.commit('toggleShowStatus', 'loading');
    salesList.value = res.data.list;
    pageInfo.value = {
      totalCnt: res.data.list.length,
      totalPage: Math.ceil(res.data.list.length / 12)
    };
    emit('setSelectCount', res.data.list.length);
  }).catch(err => {
    console.error(err);
  });
}

function getListData(pageIdx) {
  currPageIdx.value = pageIdx;
}

function goDetail(info) {
  let path = '';
  switch (info.BD_BUILD_CD) {
    case "01": path = '/parcel/parcelDetail/' + info.BY_ID; break;
    case "02": path = '/trading/tradingDetail/' + info.BY_ID; break;
    case "03": path = '/lease/leaseDetail/' + info.BY_ID; break;
  }
  if (path != '') {
    $router.push(path);
  }
  //건물의경우 지도로 이동...BD_ID를 가져가서 해당 건물을 찍어줘야함
}

defineExpose({ fnSearch })

onMounted(() => {
  fnSearch();
});

</script>