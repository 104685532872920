<template>
  <div id="mapArea" class="map_area"></div>
</template>

<script setup>
import { onMounted, defineProps, defineEmits, watch, defineExpose } from 'vue'

const emit = defineEmits(['buildDetail']);

const props = defineProps({
  buildings: Array,
  sido: Array
});

let map = null;
let buildClusterer = null;
let overClusterer = null;
let clusterer = null;

function setPosition(JIBUN_LAT, JIBUN_LNG) {
  let markerPosition = new window.kakao.maps.LatLng(JIBUN_LAT, JIBUN_LNG);
  map.setLevel(3);
  map.relayout();
  map.panTo(markerPosition);
}

defineExpose({ setPosition })

watch(
  () => props.buildings,
  (buildings) => {
    buildClusterer.clear();
    let buildMarkers = buildings.map((building) => {
      let buildPosition = new window.kakao.maps.LatLng(building.JIBUN_LAT, building.JIBUN_LNG);
      let oneBuildMarker = new window.kakao.maps.Marker({
        position: buildPosition,
        clickable: true,
        text: building.BD_NM,
        zIndex: 3,
        image: new window.kakao.maps.MarkerImage('/images/building_03.svg', new window.kakao.maps.Size(30, 40)),
        visible: function () {
          if (map.getLevel() >= 5) {
            false;
          } else {
            true;
          }
        }
      });
      let customOverlay = new window.kakao.maps.CustomOverlay({
        clickable: true,
        content: '<div class="infoBln-type1" style="left:50%;top:calc(50% - 55px);"><p style="text-align:center;">' + building.BD_NM + '</p></div>',
        position: buildPosition,
        xAnchor: 0.5,
        yAnchor: 0.5,
        zIndex: 5
      });
      customOverlay.setVisible(false);
      customOverlay.setMap(map);

      window.kakao.maps.event.addListener(oneBuildMarker, 'mouseover', function () { customOverlay.setVisible(true); });
      window.kakao.maps.event.addListener(oneBuildMarker, 'mouseout', function () { customOverlay.setVisible(false); });

      //클릭시 상세 호출
      window.kakao.maps.event.addListener(oneBuildMarker, 'click', function () {
        // 클릭시 붉은색으로 변경
        overClusterer.clear();
        let overMarker = new window.kakao.maps.Marker({
          position: buildPosition,
          clickable: true,
          title: building.BD_NM,
          zIndex: 4,
          image: new window.kakao.maps.MarkerImage('/images/building_03_purple.svg', new window.kakao.maps.Size(30, 40))
        });
        let customOverOverlay = new window.kakao.maps.CustomOverlay({
          clickable: false,
          content: '<div class="infoBln-type5" style="left:50%;top:calc(50% - 55px);"><p style="text-align:center;">' + building.BD_NM + '</p></div>',
          position: buildPosition,
          xAnchor: 0.5,
          yAnchor: 0.5,
          zIndex: 10
        });
        overClusterer.addMarker(overMarker);
        overClusterer.addMarker(customOverOverlay);
        window.kakao.maps.event.addListener(overMarker, 'click', function () {
          map.setLevel(3);
          map.relayout();
          map.panTo(buildPosition);
          emit('buildDetail', building);
        });
        map.setLevel(3);
        map.relayout();
        map.panTo(buildPosition);
        emit('buildDetail', building);
      });
      return oneBuildMarker;
    });
    buildClusterer.addMarkers(buildMarkers);
  }
);
watch(
  () => props.sido,
  (sido) => {
    clusterer.clear();
    var bounds = new window.kakao.maps.LatLngBounds();
    // var kk = 1;
    if (sido == null || sido.length == 0) {
      alert("해당조건 매물이 없습니다");
      map.setLevel(4);
      //return;
    } else {
      let markers = sido.map((sido) => {
        let markerPosition = new window.kakao.maps.LatLng(sido.JIBUN_LAT, sido.JIBUN_LNG);
        let oneMarker = new window.kakao.maps.Marker({
          position: markerPosition,
          clickable: true,
          title: sido.BD_NM,
          zIndex: 4,
          image: new window.kakao.maps.MarkerImage('/images/building_03_blue.svg', new window.kakao.maps.Size(30, 40))
        });
        oneMarker.prdCnt = sido.PRD_CNT;
        oneMarker.bdId = sido.BD_ID;
        oneMarker.markerType = "BD";
        let customOverlay = new window.kakao.maps.CustomOverlay({
          clickable: true,
          content: '<div class="infoBln-type3" style="left:50%;top:calc(50% - 55px);"><p style="text-align:center;">' + sido.BD_NM + '</p></div>',
          position: markerPosition,
          xAnchor: 0.5,
          yAnchor: 0.5,
          zIndex: 5
        });
        customOverlay.markerType = "TT";
        clusterer.addMarker(customOverlay);

        if (!sido.overlay1NotImport) {
          let customOverlay1 = new window.kakao.maps.CustomOverlay({
            clickable: true,
            content: '<div class="estWrap" style="left:10%;top:calc(50% - 20px);margin-left:15px;"><b class="num">' + sido.PRD_CNT + '</b></div>',
            position: markerPosition,
            xAnchor: 0.5,
            yAnchor: 0.5,
            zIndex: 5
          });
          customOverlay1.markerType = "CT";
          clusterer.addMarker(customOverlay1);
        }

        //지도 영역 설정을 위한 처리 (1건일경우 바로 고)
        if (sido.JIBUN_LAT != null && sido.JIBUN_LAT != "") {
          bounds.extend(new window.kakao.maps.LatLng(sido.JIBUN_LAT, sido.JIBUN_LNG))
        }
        //이벤트 등록
        window.kakao.maps.event.addListener(oneMarker, 'click', function () {

          overClusterer.clear();
          let overMarker = new window.kakao.maps.Marker({
            position: markerPosition,
            clickable: true,
            title: sido.BD_NM,
            zIndex: 4,
            image: new window.kakao.maps.MarkerImage('/images/building_03_purple.svg', new window.kakao.maps.Size(30, 40)),
          });
          let customOverOverlay = new window.kakao.maps.CustomOverlay({
            clickable: false,
            content: '<div class="infoBln-type5" style="left:50%;top:calc(50% - 55px);"><p style="text-align:center;">' + sido.BD_NM + '</p></div>',
            position: markerPosition,
            xAnchor: 0.5,
            yAnchor: 0.5,
            zIndex: 10
          });
          overClusterer.addMarker(overMarker);
          overClusterer.addMarker(customOverOverlay);

          window.kakao.maps.event.addListener(overMarker, 'click', function () {
            //네비게이션 정보 처리
            // $("#naviLat").val(sido.JIBUN_LAT);
            // $("#naviLng").val(sido.JIBUN_LNG);
            // $("#naviAddr").val(sido.BD_ADDR);
            // $("#naviBdNm").val(sido.BD_NM);
            // goBuildDetail(sido.BD_ID, sido.BY_ID, markerPosition);
            map.setLevel(3);
            map.relayout();
            map.panTo(markerPosition);
            emit('buildDetail', sido);
          });
          //네비게이션 정보 처리
          // $("#naviLat").val(sido.JIBUN_LAT);
          // $("#naviLng").val(sido.JIBUN_LNG);
          // $("#naviAddr").val(sido.BD_ADDR);
          // $("#naviBdNm").val(sido.BD_NM);
          // goBuildDetail(sido.BD_ID, sido.BY_ID, markerPosition);
          map.setLevel(3);
          map.relayout();
          map.panTo(markerPosition);
          emit('buildDetail', sido);
        });
        return oneMarker;
      });
      //클러스터러에 마커들을 추가합니다
      clusterer.addMarkers(markers);
      //markers 가 모두 나오도록 지도 범위 재설정처리			        
      map.setBounds(bounds);
    }
  }
);

function loadScript() {
  const script = document.createElement("script");
  const apiKey = "c3128f12b045dcdc0e2dba164c91ec23";
  script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&libraries=clusterer,services&autoload=false`;
  document.head.appendChild(script);
  script.onload = () => {
    window.kakao.maps.load(loadMap);
  }
}

function loadMap() {
  //맵 객체 생성
  const container = document.getElementById("mapArea");
  const option = {
    center: new window.kakao.maps.LatLng(37.45684114858621, 126.89545678002185), // 지도의 중심좌표 : 금천구 
    level: 9 // 지도의 확대 레벨 
  };
  map = new window.kakao.maps.Map(container, option);

  //클러스터 생성
  clusterer = new window.kakao.maps.MarkerClusterer({
    map: map, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
    averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
    minLevel: 5, // 클러스터 할 최소 지도 레벨,
    minClusterSize: 1,
    disableClickZoom: true,
    styles: [{ // calculator 각 사이 값 마다 적용될 스타일을 지정한다
      width: '50px', height: '50px',

      /* 20220119 클러스터 스타일 변경 */
      /* background: 'rgba(051, 102, 204, .8)', */
      background: 'rgba(66, 55, 229, .8)',
      /* 20220119 클러스터 스타일 변경 */

      borderRadius: '25px',
      color: '#fff',
      textAlign: 'center',
      fontWeight: 'bold',
      lineHeight: '51px'
    }]
  });

  buildClusterer = new window.kakao.maps.MarkerClusterer({
    map: map, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
    averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
    minLevel: 5, // 클러스터 할 최소 지도 레벨,
    minClusterSize: 1,
    texts: [""],
    styles: [{
      width: '1px', height: '1px',
      background: 'rgba(0, 0, 0, .1)',
      borderRadius: '1px',
      color: '#000',
      textAlign: 'center',
      fontWeight: 'bold',
      lineHeight: '1px',
      "z-index": -1,
    }],   //,
    disableClickZoom: false,
    clickable: false
  });

  overClusterer = new window.kakao.maps.MarkerClusterer({
    map: map, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
    averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
    minLevel: 5, // 클러스터 할 최소 지도 레벨,
    minClusterSize: 1,
    texts: [""],
    styles: [{
      width: '1px', height: '1px',
      background: 'rgba(0, 0, 0, .1)',
      borderRadius: '1px',
      color: '#000',
      textAlign: 'center',
      fontWeight: 'bold',
      lineHeight: '1px',
      "z-index": -1,
    }],   //,
    disableClickZoom: false,
    clickable: false
  });

  overClusterer.setMap(map);

  //이벤트
  window.kakao.maps.event.addListener(clusterer, 'clustered', function (clusters) {
    clusters.forEach(function (cluster) {
      let cnt = 0;
      // let clusterCnt = 0;
      cluster.getMarkers().forEach(function (maks) {

        if (maks.markerType == "BD") {
          cnt += parseInt(maks.prdCnt, 10);
          // clusterCnt++;
        }
      });
      cluster.getClusterMarker().getContent().innerText = cnt;
    })
  });

  window.kakao.maps.event.addListener(clusterer, 'clusterclick', function (cluster) {
    let clusterBounds = new window.kakao.maps.LatLngBounds();
    cluster.getMarkers().forEach(function (maks) {
      clusterBounds.extend(maks.getPosition());
    });
    map.setBounds(clusterBounds);
  });

  //요구사항에 의해서 5보다 큰경우 클러스터링 되도록 처리
  window.kakao.maps.event.addListener(map, 'tilesloaded', function () {

    if (map.getLevel() >= 5) {
      clusterer.setMinClusterSize(1);
    } else {
      clusterer.setMinClusterSize(2);
    }
    buildClusterer.redraw();
  });
}

onMounted(() => {
  if (window.kakao?.maps) {
    loadMap();
  } else {
    loadScript();
  }
});

</script>                 