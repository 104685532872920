<template>
  <form id="searchForm" name="searchForm">
    <div class="content">
      <!-- 타이틀 / 버튼 상단 영역 -->
      <div class="sub_top_area">
        <div class="top_wrap">
          <div class="t_content">
            <h3>임대검색</h3>
            <div class="middle_box">
              <h4>검색조건</h4>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_AREA_CD" @change="searchParam.BD_SIDO_CD = ''">
                    <option value="">시도</option>
                    <option v-for="areaCd in areaCdList" :key="areaCd" :value="areaCd.SIDO_ID">{{ areaCd.SIDO_NAME }}
                    </option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_SIDO_CD">
                    <option value="">구군</option>
                    <option v-for="subAreaCd in subAreaCdList" :key="subAreaCd" :value="subAreaCd.SIDO_ID">{{
                      subAreaCd.SIDO_NAME }}</option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_EXE_AREA">
                    <option value="">전용면적</option>
                    <option v-for="exeAreaCd in exeAreaCdList" :key="exeAreaCd" :value="exeAreaCd.dtlCodeId">
                      {{ exeAreaCd.dtlCodeNm }}</option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_MON_RENT">
                    <option value="">임대가</option>
                    <option v-for="monRentCd in monRentCdList" :key="monRentCd" :value="monRentCd.dtlCodeId">
                      {{ monRentCd.dtlCodeNm }}</option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="form_box">
                <div class="serach_box">
                  <div class="s_txt">
                    <button type="button" class="btn_search" @click="fnSearch"></button>
                    <input type="text" v-model="searchParam.SEARCH_NM" class="search_txt"
                      placeholder="건물명, 도로명주소, 지번주소, 지하철역" value="" @keydown.enter.prevent="fnSearch">
                  </div>
                </div>
              </div>
              <div class="btn_area on_btn">
                <!-- 클래스 on추가 시 버튼 활성화 -->
                <router-link to="/lease/leaseMap" class="btn" active-class="on">지도</router-link>
                <router-link to="/lease/leaseList" class="btn" active-class="on">목록</router-link>
              </div>
              <div class="search_option_box">
                <div class="check_box">
                  <label class="chk_label">
                    <input type="checkbox" v-model="check3D" @change="fnSearch" />
                    <i class="xi-check"></i>
                    <span>3D</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 모바일용 -->
      <div class="sub_top_area mobile" :class="{ 'on': openFilter }">
        <div class="top_wrap">
          <div class="t_content">
            <button type="button" class="btn filter" :class="{ 'on': openFilter }" @click="openFilter = !openFilter"><i
                class="xi-filter"></i>필터</button>
            <div class="btn_area on_btn">
              <!-- 클래스 on추가 시 버튼 활성화 -->
              <router-link to="/lease/leaseMap" class="btn" active-class="on">지도</router-link>
              <router-link to="/lease/leaseList" class="btn" active-class="on">목록</router-link>
            </div>
          </div>
          <div class="middle_box">
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_AREA_CD" @change="searchParam.BD_SIDO_CD = ''">
                  <option value="">시도</option>
                  <option v-for="areaCd in areaCdList" :key="areaCd" :value="areaCd.SIDO_ID">{{ areaCd.SIDO_NAME }}
                  </option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_SIDO_CD">
                  <option value="">구군</option>
                  <option v-for="subAreaCd in subAreaCdList" :key="subAreaCd" :value="subAreaCd.SIDO_ID">{{
                    subAreaCd.SIDO_NAME }}</option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_EXE_AREA">
                  <option value="">전용면적</option>
                  <option v-for="exeAreaCd in exeAreaCdList" :key="exeAreaCd" :value="exeAreaCd.dtlCodeId">
                    {{ exeAreaCd.dtlCodeNm }}</option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_MON_RENT">
                  <option value="">임대가</option>
                  <option v-for="monRentCd in monRentCdList" :key="monRentCd" :value="monRentCd.dtlCodeId">
                    {{ monRentCd.dtlCodeNm }}</option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="form_box">
              <div class="serach_box">
                <div class="s_txt">
                  <button type="button" class="btn_search" @click="fnSearch"></button>
                  <input type="text" v-model="searchParam.SEARCH_NM" class="search_txt"
                    placeholder="건물명, 도로명주소, 지번주소, 지하철역" value="" @keydown.enter.prevent="fnSearch">
                </div>
              </div>
            </div>
            <div class="search_option_box">
              <div class="check_box">
                <label class="chk_label">
                  <input type="checkbox" v-model="check3D" @change="fnSearch" />
                  <i class="xi-check"></i>
                  <span>3D</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ///모바일용 -->
      <!-- 데이터 박스 -->
      <div class="data_box" :class="{ 'full': isShowFull }">
        <router-view v-slot="{ Component }">
          <KeepAlive :max="10">
            <component ref="childRef" :is="Component" :search-param="searchParam" />
          </KeepAlive>
        </router-view>
      </div>
      <!-- ///데이터 박스 -->
    </div>
  </form>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";

const $store = useStore();
const areaCdList = computed(() => {
  return $store.getters.getSidoList('ROOT');
});
const subAreaCdList = computed(() => {
  return $store.getters.getSidoList(searchParam.value.BD_AREA_CD);
});
const exeAreaCdList = computed(() => {
  return $store.getters.getCommonCodeList('CM004');
});
const monRentCdList = computed(() => {
  return $store.getters.getCommonCodeList('CM007');
});
const isShowFull = computed(() => {
  return $store.getters.getShowStatus('full');
});


const searchParam = ref({
  BD_BUILD_CD: '03',
  SEARCH_NM: '',
  BD_AREA_CD: '',
  BD_SIDO_CD: '',
  BD_EXE_AREA: '',
  BD_MON_RENT: '',
  VIEW_3D_YN: '',
  DISP_YN: 'Y'
});

const check3D = computed({
  get: () => {
    return searchParam.value.VIEW_3D_YN == "Y";
  },
  set: (val) => {
    if (val) {
      searchParam.value.VIEW_3D_YN = "Y";
    } else {
      searchParam.value.VIEW_3D_YN = "";
    }
  }
});

const childRef = ref(null);
const openFilter = ref(false);

function fnSearch() {
  if (childRef.value.fnSearch) {
    openFilter.value = false;
    childRef.value.fnSearch();
  }
}

onMounted(() => {
  $store.dispatch('dispatchCommonCodeList');
  $store.dispatch('dispatchSidoList');
});

</script>
