<template>
  <form id="searchForm" name="searchForm">
    <div class="content search">
      <!-- 타이틀 / 버튼 상단 영역 -->
      <div class="sub_top_area">
        <div class="top_wrap">
          <div class="t_content">
            <h3>통합검색</h3>
            <div class="middle_box">
              <h4>검색조건</h4>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_AREA_CD" @change="searchParam.BD_SIDO_CD = ''">
                    <option value="">시도</option>
                    <option v-for="areaCd in areaCdList" :key="areaCd" :value="areaCd.SIDO_ID">{{ areaCd.SIDO_NAME }}
                    </option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="select_box">
                <div class="s_txt">
                  <select class="select_list" v-model="searchParam.BD_SIDO_CD">
                    <option value="">구군</option>
                    <option v-for="subAreaCd in subAreaCdList" :key="subAreaCd" :value="subAreaCd.SIDO_ID">{{
                    subAreaCd.SIDO_NAME }}</option>
                  </select>
                  <span class="arrow"><i class="xi-angle-down"></i></span>
                </div>
              </div>
              <div class="form_box">
                <div class="serach_box">
                  <div class="s_txt">
                    <button type="button" class="btn_search" @click="fnSearch"></button>
                    <input type="text" v-model="searchParam.SEARCH_NM" class="search_txt"
                      placeholder="건물명, 도로명주소, 지번주소, 지하철역" value="" @keydown.enter.prevent="fnSearch">
                  </div>
                </div>
              </div>
              <div class="btn_area on_btn">
                <!-- 클래스 on추가 시 버튼 활성화 -->
                <router-link to="/total/totalMap" class="btn" active-class="on">지도</router-link>
                <router-link to="/total/totalList" class="btn" active-class="on">목록</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 모바일용 -->
      <div class="sub_top_area mobile" :class="{ 'on': openFilter }">
        <div class="top_wrap">
          <div class="t_content">
            <button type="button" class="btn filter" :class="{ 'on': openFilter }" @click="openFilter = !openFilter"><i
                class="xi-filter"></i>필터</button>
            <div class="btn_area on_btn">
              <!-- 클래스 on추가 시 버튼 활성화 -->
              <router-link to="/total/totalMap" class="btn" active-class="on">지도</router-link>
              <router-link to="/total/totalList" class="btn" active-class="on">목록</router-link>
            </div>
          </div>
          <div class="middle_box">
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_AREA_CD" @change="searchParam.BD_SIDO_CD = ''">
                  <option value="">시도</option>
                  <option v-for="areaCd in areaCdList" :key="areaCd" :value="areaCd.SIDO_ID">{{ areaCd.SIDO_NAME }}
                  </option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="select_box">
              <div class="s_txt">
                <select class="select_list" v-model="searchParam.BD_SIDO_CD">
                  <option value="">구군</option>
                  <option v-for="subAreaCd in subAreaCdList" :key="subAreaCd" :value="subAreaCd.SIDO_ID">{{
                    subAreaCd.SIDO_NAME }}</option>
                </select>
                <span class="arrow"><i class="xi-angle-down"></i></span>
              </div>
            </div>
            <div class="form_box">
              <div class="serach_box">
                <div class="s_txt">
                  <button type="button" class="btn_search" @click="fnSearch"></button>
                  <input type="text" v-model="searchParam.SEARCH_NM" class="search_txt"
                    placeholder="건물명, 도로명주소, 지번주소, 지하철역" value="" @keydown.enter.prevent="fnSearch">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ///모바일용 -->
      <!-- 통합 검색 타이틀 / 버튼 상단 영역 -->
      <div class="sub_top_area search">
        <div class="top_wrap">
          <div class="t_content">
            <h3>검색결과 {{ selectCount }}건</h3>
            <div class="middle_box">
              <ul>
                <li><a href="javascript:;" class="btn" :class="{ 'on': searchParam.BD_BUILD_CD == '' }"
                    @click="fnView('')">건물({{ searchCount.T_CNT }})</a></li>
                <li><a href="javascript:;" class="btn" :class="{ 'on': searchParam.BD_BUILD_CD == '01' }"
                    @click="fnView('01')">분양({{ searchCount.B_CNT }})</a></li>
                <li><a href="javascript:;" class="btn" :class="{ 'on': searchParam.BD_BUILD_CD == '02' }"
                    @click="fnView('02')">매매({{ searchCount.M_CNT }})</a></li>
                <li><a href="javascript:;" class="btn" :class="{ 'on': searchParam.BD_BUILD_CD == '03' }"
                    @click="fnView('03')">임대({{ searchCount.I_CNT }})</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- ///통합 검색 타이틀 / 버튼 상단 영역 -->

      <!-- 데이터 박스 -->
      <div class="data_box">
        <router-view v-slot="{ Component }">
          <component ref="childRef" :is="Component" :search-param="searchParam" @set-select-count="setSelectCount" />
        </router-view>
      </div>
      <!-- ///데이터 박스 -->
    </div>
  </form>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import axios from 'axios'

const $store = useStore();
const $route = useRoute();
const areaCdList = computed(() => {
  return $store.getters.getSidoList('ROOT');
});
const subAreaCdList = computed(() => {
  return $store.getters.getSidoList(searchParam.value.BD_AREA_CD);
});

const searchParam = ref({
  BD_BUILD_CD: '',
  SEARCH_NM: '',
  BD_AREA_CD: '',
  BD_SIDO_CD: ''
});

const searchCount = ref({
  T_CNT: 0,
  B_CNT: 0,
  M_CNT: 0,
  I_CNT: 0
});

const selectCount = ref(0);

const childRef = ref(null);
const openFilter = ref(false);

function fnSearch() {
  axios({
    url: "/sub/totalCount.do",
    method: "POST",
    data: searchParam.value
  }).then(res => {
    searchCount.value.T_CNT = res.data.search.T_CNT;
    searchCount.value.B_CNT = res.data.search.B_CNT;
    searchCount.value.M_CNT = res.data.search.M_CNT;
    searchCount.value.I_CNT = res.data.search.I_CNT;
  }).catch(err => {
    console.error(err);
  });

  if (childRef.value.fnSearch) {
    openFilter.value = false;
    childRef.value.fnSearch();
  } else { //ex.상세페이지

  }
}

function fnView(bdBuildCd) {
  searchParam.value.BD_BUILD_CD = bdBuildCd;
  if (childRef.value.fnSearch) {
    childRef.value.fnSearch();
  }
}

function setSelectCount(cnt) {
  selectCount.value = cnt;
}

onMounted(() => {
  $store.dispatch('dispatchCommonCodeList');
  $store.dispatch('dispatchSidoList');
  if ($route.query.SEARCH_NM) {
    searchParam.value.SEARCH_NM = $route.query.SEARCH_NM;
  }
  fnSearch();
});

</script>
