<template>
  <div class="pagination_wrap">
    <div class="pagination_btn_wrap">
      <a href="javascript:;" @click="goPage(1)" class="prev_first"></a>
      <a href="javascript:;" @click="goPrevPage()" class="prev"><i class="xi-angle-left"></i></a>
    </div>
    <!-- 활성화는 active 클래스 추가 -->
    <ul class="pagination">
      <li class="page_btn" :class="{ 'current active': page == currentPage }" v-for="page in pageList" :key="page">
        <a href="javascript:;" @click="goPage(page)">{{ page }}</a>
      </li>
    </ul>
    <div class="pagination_btn_wrap">
      <a href="javascript:;" @click="goNextPage()" class="next"><i class="xi-angle-right"></i></a>
      <a href="javascript:;" @click="goPage(props.totalPage)" class="prev_next"></a>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, defineProps } from 'vue'
const emit = defineEmits(['getPageData']);
const props = defineProps({
  totalCnt: Number,
  totalPage: Number
});
const currentPage = ref(1);
const startPage = computed(() => {
  return Math.floor((currentPage.value - 1) / 5) * 5 + 1;
});
const endPage = computed(() => {
  return props.totalPage > startPage.value + 4 ? startPage.value + 4 : props.totalPage;
});
const pageList = computed(() => {
  const rtnArr = [];
  for (let i = startPage.value; i <= endPage.value; i++) {
    rtnArr.push(i);
  }
  return rtnArr;
});

function goPage(pageIdx) {
  if (currentPage.value != pageIdx) {
    currentPage.value = pageIdx;
    emit('getPageData', currentPage.value);
  }
}

function goPrevPage() {
  let idx = 0;
  if (startPage.value > 5) {
    idx = startPage.value - 5;
  } else {
    idx = 1;
  }
  goPage(idx);
}

function goNextPage() {
  let idx = 0;
  if (startPage.value + 5 <= props.totalPage) {
    idx = startPage.value + 5;
  } else {
    idx = props.totalPage;
  }
  goPage(idx);
}

</script>