<template>
  <div class="data_content" v-if="info">
    <div class="detail_map_layer detail">
      <div class="mlayer_wrap">
        <div class="top_cont">
          <h3>{{ info.BD_NM }}</h3>
          <span id="bdAddr">{{ info.BD_ADDR ? info.BD_ADDR : info.BD_JIBUN_ADDR }}</span>
        </div>
        <div class="bulid_info_wrap info_txt_box" style="display: block;">
          <ul>
            <li>
              <h4 @click="$event.target.offsetParent.classList.toggle('off')">매매정보</h4>
              <div class="info_txt">
                <div class="btn_area on_btn">
                  <a href="javascript:;" @click="areaShowType = 'sqm'" class="btn"
                    :class="{ 'on': areaShowType == 'sqm' }">㎡</a><!-- //활성화 class: on 추가 -->
                  <a href="javascript:;" @click="areaShowType = 'flat'" class="btn"
                    :class="{ 'on': areaShowType == 'flat' }">py</a>
                </div>
                <dl>
                  <dt>중개대상물의종류</dt>
                  <dd>{{ buildTypeName }}</dd>

                  <dt>사용승인일</dt>
                  <dd v-if="info.BD_BUILD_CD == '04'">현재 분양중인 건물입니다.</dd>
                  <dd v-else-if="info.USE_APP_YMD">{{ info.USE_APP_YMD.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') }}
                  </dd>
                  <dd v-else></dd>

                  <dt>총주차대수</dt>
                  <dd>{{ buildParkingCount }}</dd>

                  <dt>방향</dt>
                  <dd>{{ directionName }}</dd>

                  <template v-if="info.BD_BUILD_CD != '04'">
                    <dt>관리비(3.3㎡)</dt>
                    <dd>{{ common.comma(info.MAIN_COST) }} 원</dd>
                  </template>

                  <dt>총층수</dt>
                  <dd>{{ info.FLOOR_TXT }} 층</dd>

                  <dt>해당층수</dt>
                  <dd>{{ info.BD_FLOOR }} 층</dd>

                  <dt>공급면적</dt>
                  <dd v-if="areaShowType == 'sqm'">{{ info.BD_APP_AREA }} ㎡</dd>
                  <dd v-else-if="areaShowType == 'flat'">{{ (Number(info.BD_APP_AREA) / 3.306).toFixed(1) }} py</dd>

                  <dt>전용면적</dt>
                  <dd v-if="areaShowType == 'sqm'">{{ info.BD_EXE_AREA }} ㎡</dd>
                  <dd v-else-if="areaShowType == 'flat'">{{ (Number(info.BD_EXE_AREA) / 3.306).toFixed(1) }} py</dd>

                  <template v-if="info.BD_BUILD_CD == '01'">
                    <dt>매매가</dt>
                    <dd>{{ common.comma(info.BD_PRICE / 10000) }} 만원</dd>
                  </template>
                  <template v-else-if="info.BD_BUILD_CD == '02'">
                    <dt>매매가</dt>
                    <dd>{{ common.comma(info.BD_PRICE / 10000) }} 만원</dd>
                  </template>
                  <template v-else-if="info.BD_BUILD_CD == '03'">
                    <dt>보증금</dt>
                    <dd>{{ common.comma(info.BD_DEPOSIT / 10000) }} 만원</dd>
                    <dt>임대료</dt>
                    <dd>{{ common.comma(info.BD_MON_RENT / 10000) }} 만원</dd>
                  </template>
                  <template v-else-if="info.BD_BUILD_CD == '04'">
                    <dt>매매가</dt>
                    <dd>{{ common.comma(info.BD_PRICE / 10000) }} 만원</dd>
                  </template>

                  <dt>입주가능일</dt>
                  <dd>{{ moveIntoDtName }}</dd>
                </dl>
              </div>
            </li>
            <li>
              <h4 @click="$event.target.offsetParent.classList.toggle('off')">매물특징</h4>
              <div class="info_txt">
                <span v-html="bdFeature">
                </span>
              </div>
            </li>
            <li>옵션정보
              <div class="info_txt">
                <span>
                  {{ bdOptionNames }}
                </span>
              </div>
            </li>
            <li>연락처
              <div class="info_txt">
                <span>{{ info.CONTACT_TEL }}</span>
              </div>
            </li>
          </ul>
          <span class="bottom_txt">
            직거래의 경우 거래내용에 관한 모든 책임은<br>
            거래 당사자간에 있음을 사전고지 드립니다.
          </span>
        </div>
      </div>
    </div>
    <!-- 스와이퍼 슬라이드 -->
    <div class="swiper mainVisual">
      <div class="swiper-wrapper">
        <li class="swiper-slide" v-if="info.MATTER_PORT_URL != ''">
          <div class="imgWrap border">
            <iframe width="100%" height="100%" class="matterport3d" :src="info.MATTER_PORT_URL" frameborder="0"
              allowfullscreen allow="xr-spatial-tracking"></iframe>
          </div>
        </li>
        <template v-for="img in imageList" :key="img">
          <li class="swiper-slide">
            <div class="imgWrap border">
              <img :src="'/image/' + img + '/view.do'" alt="" @error="common.replaceDefaultImageDetail" />
            </div>
          </li>
        </template>
        <li class="swiper-slide" v-if="imageList.length == 0 && info.MATTER_PORT_URL == ''">
          <div class="imgWrap border">
            <img src="@/assets/images/noimage.jpg" alt="" />
          </div>
        </li>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
    <!-- ///스와이퍼 슬라이드 -->
  </div>
</template>

<script setup>
import Swiper from '@/assets/js/swiper.min.js'
import { ref, onActivated, onDeactivated, computed, watch, defineExpose } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex";
import axios from 'axios'
import common from '@/assets/js/ui.common.js'

const $store = useStore();
const $route = useRoute();
const $router = useRouter();
const info = ref({});
const buildTypeName = computed(() => {
  let rtn = "";
  switch (info.value.BUILD_TYPE) {
    case "01": rtn = "지식산업센터"; break;
    case "02": rtn = "근린상가"; break;
    case "03": rtn = "업무시설"; break;
    case "04": rtn = "기숙사"; break;
    case "05": rtn = "창고"; break;
    case "06": rtn = "일반공장"; break;
    case "07": rtn = "토지"; break;
    default: rtn = info.value.BUILD_TYPE;
  }
  return rtn;
});
const buildParkingCount = computed(() => {
  let parkingCnt = 0;
  if (info.value.BD_INNER_CAR) {
    parkingCnt += parseInt(info.value.BD_INNER_CAR, 10);
  }
  if (info.value.BD_OUTER_CAR) {
    parkingCnt += parseInt(info.value.BD_OUTER_CAR, 10);
  }
  return parkingCnt + "대";
});

const isShowFull = computed(() => {
  return $store.getters.getShowStatus('full');
});

const directionName = computed(() => {
  let rtn = "";
  switch (info.value.BD_DIRECTION) {
    case "01": rtn = "동향(발코니)"; break;
    case "02": rtn = "서향(발코니)"; break;
    case "03": rtn = "남향(발코니)"; break;
    case "04": rtn = "북향(발코니)"; break;
    case "05": rtn = "북동향(발코니)"; break;
    case "06": rtn = "남동향(발코니)"; break;
    case "07": rtn = "남서향(발코니)"; break;
    case "08": rtn = "북서향(발코니)"; break;
  }
  return rtn;
});

const moveIntoDtCdList = computed(() => {
  return $store.getters.getCommonCodeList('F01');
});

const moveIntoDtName = computed(() => {
  let rtn = "";
  if (info.value.MOVE_INTO_DT) {
    let codeObj = moveIntoDtCdList.value.find((vo) => vo.dtlCodeId == info.value.MOVE_INTO_DT)
    if (codeObj) {
      rtn = codeObj.dtlCodeNm;
    }
  }
  return rtn;
});

const bdOptionCdList = computed(() => {
  return $store.getters.getCommonCodeList('CM002');
});

const bdOptionNames = computed(() => {
  let rtn = "";
  if (info.value.BD_OPTION_CODES) {
    const nameArr = info.value.BD_OPTION_CODES.split(',').map((code) => {
      let codeObj = bdOptionCdList.value.find((vo) => vo.dtlCodeId == code);
      if (codeObj) {
        return codeObj.dtlCodeNm;
      }
    });
    rtn = nameArr.join(', ');
  }
  return rtn;
});

const areaShowType = ref('sqm');

const imageList = computed(() => {
  let rtn = [];
  for (let i = 1; i <= 20; i++) {
    if (info.value['FILE_ID' + i]) {
      rtn.push(info.value['FILE_ID' + i]);
    }
  }
  return rtn;
});

const bdFeature = computed(() => {
  let rtn = "";
  if (info.value.BD_FEATURE) {
    rtn = info.value.BD_FEATURE.replaceAll("\n", "<br />");
  }
  return rtn;
});

function fnSearch() {
  $router.push('/trading/tradingMap');
}

defineExpose({ fnSearch })

watch(
  () => imageList.value,
  () => {
    setTimeout(function () {
      new Swiper(".mainVisual", {
        slidesPerView: 1,
        // spaceBetween: 16,
        allowTouchMove: true,
        // autoplay: {     //자동슬라이드 (false-비활성화)
        //   delay: 3000, // 시간 설정
        //   disableOnInteraction: false, // false-스와이프 후 자동 재생
        // },
        loop: false,   // 슬라이드 반복 여부
        loopAdditionalSlides: 1,
        // 슬라이드 반복 시 마지막 슬라이드에서 다음 슬라이드가 보여지지 않는 현상 수정

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        },
      });
    }, 1);
  }
);

onActivated(() => {
  info.value = {};
  if (!isShowFull.value) {
    $store.commit('toggleShowStatus', 'full');
  }
  axios({
    url: "/sub/selectCentBuildByDetail.do",
    method: "POST",
    data: { BY_ID: $route.params.byId }
  }).then(res => {
    info.value = res.data.info;
  }).catch(err => {
    console.error(err);
  });
});
onDeactivated(() => {
  if (isShowFull.value) {
    $store.commit('toggleShowStatus', 'full');
  }
});

</script>