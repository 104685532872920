<template>
  <div class="content board"> <!-- 게시판에만 클래스 board추가 -->
    <!-- 타이틀 -->
    <div class="sub_top_area">
      <div class="top_wrap">
        <div class="t_content">
          <h3>공지사항</h3>
        </div>
      </div>
    </div>
    <!-- ///타이틀 -->

    <!-- 데이터 박스 -->
    <div class="data_box">
      <router-view v-slot="{ Component }">
        <KeepAlive :max="10">
          <component ref="childRef" :is="Component" />
        </KeepAlive>
      </router-view>
    </div>
    <!-- ///데이터 박스 -->
  </div>
</template>