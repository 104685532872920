import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import PolicyView from '../views/PolicyView.vue'
import PrivateView from '../views/PrivateView.vue'
import LocationView from '../views/LocationView.vue'
import ManagementListView from '../views/ManagementListView.vue'
import ManagementWriteView from '../views/ManagementWriteView.vue'
import AboutView from '../views/AboutView.vue'
import TotalView from '../views/total/TotalView.vue'
import TotalListView from '../views/total/TotalListView.vue'
import TotalMapView from '../views/total/TotalMapView.vue'
import TradingView from '../views/trading/TradingView.vue'
import TradingListView from '../views/trading/TradingListView.vue'
import TradingMapView from '../views/trading/TradingMapView.vue'
import TradingDetailView from '../views/trading/TradingDetailView.vue'
import LeaseView from '../views/lease/LeaseView.vue'
import LeaseListView from '../views/lease/LeaseListView.vue'
import LeaseMapView from '../views/lease/LeaseMapView.vue'
import LeaseDetailView from '../views/lease/LeaseDetailView.vue'
import ParcelView from '../views/parcel/ParcelView.vue'
import ParcelListView from '../views/parcel/ParcelListView.vue'
import ParcelMapView from '../views/parcel/ParcelMapView.vue'
import ParcelDetailView from '../views/parcel/ParcelDetailView.vue'
import NoticeView from '../views/board/NoticeView.vue'
import NoticeListView from '../views/board/NoticeListView.vue'
import NoticeDetailView from '../views/board/NoticeDetailView.vue'
import NoticeWriteView from '../views/board/NoticeWriteView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/policy',
    name: 'policy',
    component: PolicyView
  },
  {
    path: '/private',
    name: 'private',
    component: PrivateView
  },
  {
    path: '/location',
    name: 'location',
    component: LocationView
  },
  {
    path: '/managementList',
    name: 'managementList',
    component: ManagementListView
  },
  {
    path: '/managementWrite',
    name: 'managementWrite',
    component: ManagementWriteView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/total',
    name: 'total',
    component: TotalView,
    children: [
      {
        path: 'totalList',
        name: 'totalList',
        component: TotalListView
      },
      {
        path: 'totalMap',
        name: 'totalMap',
        component: TotalMapView
      }
    ]
  },
  {
    path: '/trading',
    name: 'trading',
    component: TradingView,
    children: [
      {
        path: 'tradingList',
        name: 'tradingList',
        component: TradingListView
      },
      {
        path: 'tradingMap',
        name: 'tradingMap',
        component: TradingMapView
      },
      {
        path: 'tradingDetail/:byId',
        name: 'tradingDetail',
        component: TradingDetailView
      }
    ]
  },
  {
    path: '/lease',
    name: 'lease',
    component: LeaseView,
    children: [
      {
        path: 'leaseList',
        name: 'leaseList',
        component: LeaseListView
      },
      {
        path: 'leaseMap',
        name: 'leaseMap',
        component: LeaseMapView
      },
      {
        path: 'leaseDetail/:byId',
        name: 'leaseDetail',
        component: LeaseDetailView
      }
    ]
  },
  {
    path: '/parcel',
    name: 'parcel',
    component: ParcelView,
    children: [
      {
        path: 'parcelList',
        name: 'parcelList',
        component: ParcelListView
      },
      {
        path: 'parcelMap',
        name: 'parcelMap',
        component: ParcelMapView
      },
      {
        path: 'parcelDetail/:byId',
        name: 'parcelDetail',
        component: ParcelDetailView
      }
    ]
  },
  {
    path: '/notice',
    name: 'notice',
    component: NoticeView,
    children: [
      {
        path: 'noticeList',
        name: 'noticeList',
        component: NoticeListView
      },
      {
        path: 'noticeDetail/:idx',
        name: 'noticeDetail',
        component: NoticeDetailView
      },
      {
        path: 'noticeWrite',
        name: 'noticeWrite',
        component: NoticeWriteView
      }]
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  },
  routes
})

export default router