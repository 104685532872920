<template>
  <div class="data_content" v-if="board">
    <div class="board_view">
      <div class="t_content">
        <h3>{{ board.TITLE }}</h3>
        <span class="date">{{ board.CREA_DTM_STR }}</span>
      </div>
      <div class="view_area" v-html="contents">
      </div>
      <ul class="file_area add_btn_area" style="display: flex;" v-if="isFile">
        <template v-for="idx in 5" :key="idx">
          <li v-if="board[`FILE_INFO${idx}`]">
            <span class="board_ico"></span>
            <a href="javascript:;" @click="fileDownload(board[`FILE_INFO${idx}`])">
              {{ board[`FILE_INFO${idx}`].FILE_ORG_NAME }}</a>
          </li>
        </template>
      </ul>
    </div>
  </div>

  <div class="btn_area more">
    <router-link :to="{ path: '/notice/noticeWrite', query: { IDX: board.IDX } }" class="btn"
      v-if="isLogin">수정</router-link>
    <a href="javascript:;" class="btn" @click="goDelete" v-if="isLogin">삭제</a>
    <a href="javascript:;" class="btn" @click="goBack">목록</a>
  </div>
</template>

<script setup>
// import common from '@/assets/js/ui.common.js'
import { ref, onActivated, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex";
import axios from 'axios'

const $store = useStore();
const $route = useRoute();
const $router = useRouter();
const board = ref({});

const isLogin = computed(() => {
  return $store.getters.getIsLogin();
});

const contents = computed(() => {
  let rtn = "";
  if (board.value.CONTENTS) {
    rtn = board.value.CONTENTS.replaceAll("\n", "<br />");
  }
  return rtn;
});

const isFile = computed(() => {
  let rtn = false;
  if (board.value) {
    for (let i = 1; i <= 5; i++) {
      if (board.value[`FILE_INFO${i}`]) {
        rtn = true;
        break;
      }
    }
  }
  return rtn;
});

function goBack() {
  $router.back();
}

function goDelete() {
  //axios
  axios({
    url: "/board/deleteBoard.do",
    method: "POST",
    data: { IDX: board.value.IDX }
  }).then(res => {
    if (res.data.delCnt > 0) {
      alert("게시물이 삭제되었습니다.");
      goBack();
    } else {
      alert("삭제실패");
    }
  }).catch(err => {
    console.error(err);
  });
}
/* eslint-disable */

function fileDownload(fileInfo) {
  axios({
    url: "/board/downloadFile.do",
    method: "POST",
    responseType: "blob",
    data: {
      'FILE_NAME': fileInfo.FILE_NAME
    }
  }).then(res => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileInfo.FILE_ORG_NAME); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }).catch(err => {
    console.error(err);
  });
}

onActivated(() => {
  axios({
    url: "/board/seleteBoardDetail.do",
    method: "POST",
    data: { IDX: $route.params.idx }
  }).then(res => {
    board.value = res.data.board;
  }).catch(err => {
    console.error(err);
  });
});

</script>